<template>
<div class="shadow-sm">
    <b-navbar id="nev-bar" toggleable="lg" type="light" fixed="top" class="nev-bar-bg shadow-sm" >
        <b-container>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-navbar-brand
                @click="goToLink('/')"
                id="header-logo">
                <img 
                    :src="require('@/assets/images/Logo.svg')"
                    height="37px"
                    width="156px"
                    class="clikable"
                    alt="tripplanner">
            </b-navbar-brand>

            <b-collapse id="nav-collapse" class='main-menu' is-nav>
                <b-navbar-nav >
                    <b-nav-item style="margin-left:1.5rem; margin-right:1.5rem;" class='main-menu' @click="goToLink('/')">דף הבית</b-nav-item>
                    <b-nav-item style="margin-left:1.5rem; margin-right:1.5rem;" @click="goToLink('/about')">אודות</b-nav-item>
                    <b-nav-item style="margin-left:1.5rem; margin-right:1.5rem;" @click="goToLink('/search_ride')">צפיה בטיולים</b-nav-item>
                    <b-nav-item style="margin-left:1.5rem; margin-right:1.5rem;" v-if="isAppLoggedIn" @click="goToLink('/user_rides')">הטיולים שלי</b-nav-item>
                </b-navbar-nav>
            </b-collapse>

            <a
             href='https://www.facebook.com/tripplanner.co.il'
             aria-label="goo to TripPlanner facebook page"
             target='_blank'>
                <img
                :src="require('@/assets/images/facebook_nav_bar_icon.png')"
                height="24px"
                width="26px"
                alt="facebookIcon"
                class="nev-bar_facebook_icon">
            </a>

            <div v-if="isAppLoggedIn" class="horizontal">
               <a class="trip-header-btn" @click="appLogOut">התנתקות</a>
            </div>
            <div v-else >
                <a class="trip-header-btn" @click="openLoginForm">התחברות</a>
            </div>
        </b-container>
    </b-navbar>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  computed: mapGetters(['isAppLoggedIn',
                        'userName',
                        'email']),
  methods: { ...mapActions(['login', 'appLogOut','validateAndupdateAppToken', 'openLoginForm']),
      goToLink( link ) {
      this.$router.push(link)
    },
  },
  created() {
    this.validateAndupdateAppToken();
  }

};
</script>

<style scoped>

.form-popup {
  display: none;
}

.shadow-sm {
  box-shadow: 0 .8rem .5rem rgba(0, 0, 0, .075) !important;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Set font for body */
body {
  font-family: 'Noto Sans Hebrew', sans-serif;
  color: #555;
  text-align: right;
}

/* Navbar background and styling */
.nev-bar-bg {
  background-color: white;
  min-height: 50px;
}

/* Navigation item styles */
.main-menu {
  font-weight: 700;
  font-size: 18px;
  color: white;
  font-family: 'Noto Sans Hebrew', sans-serif;
}

/* Trip header button styling */
a.trip-header-btn {
  display: inline-block;
  font-size: 21px;
  font-weight: 400;
  color: #1f1f1f;
  padding: 8px 21px;
  border-radius: 50px;
  margin-right: 45px;
  font-family: 'Noto Sans Hebrew', sans-serif;
}

a.trip-header-btn:hover {
  cursor: pointer;
}

/* Facebook icon in navbar */
.nev-bar_facebook_icon {
  height: 24px;
  width: 26px;
}


/* Import font */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@400;600;700&family=Rubik:wght@400;500;700&display=swap');

/* Base Font and Styles */
body {
  font-family: 'Noto Sans Hebrew', sans-serif;
}

/* Navbar and Button Style Adjustments */

/* For Mobile (320px - 480px) */
@media (max-width: 30em) { /* 480px */
  a.trip-header-btn {
    padding: 2px 5px;
    margin-right: 0;
    font-size: 14px;
  }

  .nev-bar_facebook_icon {
    display: none;
  }
}

/* Tablet and Small Screen Sizes (481px - 768px) */
@media (max-width: 48em) { /* 768px */
  .trip-header-section {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .trip-header-btn {
    font-size: 16px;
  }

  .main-menu {
    font-size: 16px;
    margin-right: 1rem;
  }
  .global_header_text {
    margin-right: 5rem;
  }

  /* Trip Header Logo Adjustments */
  #header-logo img {
    width: 100px;
    height: auto;
  }
}

/* Medium Screens (769px - 1024px) */
@media (max-width: 64em) { /* 1024px */
  .trip-header-section {
    padding: 1rem 0;
  }

  .trip-header-btn {
    font-size: 18px;
    padding: 6px 15px;
    margin-right: 10px;
  }

  .main-menu {
    font-size: 18px;
  }
}

/* Large Screens (1025px - 1200px) */
@media (max-width: 75em) { /* 1200px */
  /* Minor adjustments for larger screens */
  .trip-header-btn {
    font-size: 20px;
    padding: 8px 20px;
  }

  .main-menu {
    font-size: 20px;
  }
}

/* For 360px Screens and Below */
@media (max-width: 22.5em) { /* 360px */
  .trip-card-item {
    padding: 10px;
  }
  .corner-image {
    top: -19px;
    right: -3px;
  }
}

</style>

