<template>
  <div class="about" style="position: relative">
   <title>טריפ-פלנר - הדרך הקלה לתכנן טיול בחו"ל, בלי להתבלבל!</title>
   <SingleRideHeader
      presentedText="אודות"
    />
    <b-container>
      <div class="trip-about-section after_page_header">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="trip-about-content">
                <h1>טריפ-פלנר - הדרך הקלה לתכנן טיול בחו"ל!</h1>
                <p>טריפ-פלנר הוא אתר לשיתוף מסלולי טיול בחו"ל בעברית. אנחנו יודעים כמה קשה לתכנן טיול בחו"ל, במיוחד כשיש כל כך הרבה מידע זמין. לכן, הקמנו את טריפ-פלנר - אתר שמאגד עבורכם את המידע שאתם צריכים כדי לתכנן את הטיול המושלם, בקלות ובפשטות.</p>
                <p>האתר מציג מסלולים שמטילים אחרים עשו, לאזורים שונים בעולם. כרגע, מרבית המסלולים הם באירופה, אבל אנחנו תמיד מחפשים להוסיף עוד! עבור כל טיול מוצגים הפרטים הבאים:</p>
                <ul>
                  <li>חלוקת הימים לאטרקציות</li>
                  <li>המלצות על מקומות לינה</li>
                  <li>טיפים מאנשים נוספים</li>
                  <li>הצגת כל הנקודות על המפה</li>
                  <li>שדות תעופה מומלצים</li>
                </ul>
                <p>הנתונים האלה נותנים למתכנן הטיול את המידע שהוא צריך כדי לתכנן את המסלול שלו בצורה מוצלחת.</p>
                <p>טריפ-פלנר הוא הפתרון לבעיה שלכם. הוא מאפשר לכם למצוא את המסלול המתאים עבורכם, בקלות ובמהירות. אנחנו מזמינים אתכם להוסיף מסלולים לאתר! הוספת מסלול היא פעולה פשוטה - פשוט שלחו אלינו לוואטסאפ אלבום תמונות מהטיול שלכם. איך עושים את זה? תלמדו הכל בסרטון ההדרכה:</p>
                <a href="https://youtu.be/iXaEyBidYZY" target="_blank">צפו בסרטון ההדרכה</a>
                <p class="spaced">טריפ-פלנר בתחילת דרכו ואנחנו זקוקים לעזרתכם כדי להתפתח. אם יש לכם טיול שתרצו לשתף, נשמח לשמוע. הוספת מסלול היא פעולה פשוטה, שתהפוך את האתר שלנו לשימושי עוד יותר. יחד, ניצור את האתר הטוב ביותר לתכנון טיולים בחו"ל</p>
                <p class="disclaimer">באתר קיימים לינקים להזמנת מלונות ואטרקציות, וטריפ-פלנר מקבל עמלה קטנה מכל הזמנה שנעשית דרך האתר. זה עוזר לנו לממן את עלויות האתר, כך שלא משלמים עבור השימוש באתר.</p>
                <p> אנחנו כאן עבורכם בכל שאלה או עזרה. צרו איתנו קשר ונשמח לעזור!</p>

                <p>תודה,</p>
                <p>אבשלום כץ</p>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="trip-about-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fabout.png?alt=media&token=a4db5c6e-fe98-47fa-a731-9fe5a7e4a968"
                 class="rounded-image"
                 alt="">
              </div>
         
            </div>
            
          </div>
        </div>
      </div>
      <!-- End about section -->

      <div class="trip-cta-section">
        <div class="container">
          <div class="trip-cta-wrap">
            <h2>פרטי התקשרות</h2>
            <div class="trip-cta-menu">
              <ul>
                <li><a href="">טלפון : 052-686-7915 </a></li>
                <li>|</li>
                <li><a href="">אימייל:     avshalom@tripplanner.co.il </a></li>
                <li>|</li>
                <li><a href=""> כתובת: סלעית 4588500  </a></li>
              </ul>
            </div>
            <div class="trip-social">
              <ul>
                <!-- <li><a href=""><img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fsocial-icon-telegram.svg?alt=media&token=21ecf836-710c-4fba-a69b-c74ca59ec7a5" alt=""></a></li> -->
                <li style="margin-left: 1em;">
                  <a href="https://wa.me/972526867915">
                    <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fsocial-icon-whatsapp.svg?alt=media&token=7f535993-bc69-4a53-b764-44c4dce0217d" alt="">
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/tripplanner.co.il">
                    <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fsocial-icon-face-book.svg?alt=media&token=a3b98c59-95e9-4cb5-a6dc-04f1236d9e93" alt="">
                  </a>
                </li>
                <!-- <li><a href=""><img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fsocial-icon-mail.svg?alt=media&token=544ee52a-6eb3-4f89-9900-25560e7f6e98" alt=""></a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    
    </b-container>
    <PageFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageFooter from "@/components/PageFooter.vue";
import SingleRideHeader from "@/components/SingleRideHeader";

export default {
  name: "AboutPage",
  components: {
    PageFooter,
    SingleRideHeader,    
  },
  computed: mapGetters(['globalRides', 'userId' ]),
  methods: {},
  metaInfo() {
    return {
      title: 'TripPlanner',
      link: [
        { property: 'og:title', content: 'TripPlanner' },
        { property: 'og:description', content: 'מאגר טיולים גדול של מטיילים ישראלים ששיתפו את המסלולים שלהם' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://tripplanner.co.il/about` },
        // { property: 'og:image', content: 'https://tripplanner.co.il/image-url.jpg' },
        { name: 'description', content: 'מאגר טיולים גדול של מטיילים ישראלים ששיתפו את המסלולים שלהם' },
        // { name: 'keywords', content: 'keyword1, keyword2, keyword3' },
        // { rel: 'canonical', href: "https://tripplanner.co.il/about" }
      ]
    };
  }
}
</script>

<style scoped>

body {
  font-family: Arial, sans-serif;
  margin: 20px;
}
h1 {
  font-weight: bold;
  font-size: 2.5em;
  margin-bottom: 10px;
}
p {
  line-height: 1.5;
  margin-bottom: 10px;
}
a {
  font-size: 1.5em;
  color: #007bff;
  text-decoration: none;
}
li {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
ul {
  padding-right: 4em;
}
.spaced {
margin-top: 0.6em;
}
.disclaimer {
  font-size: 0.8em;
  margin-top: 20px;
}


.after_page_header{
  margin-top: 20rem;
}
@media (max-width: 991px) {
  .after_page_header{
    margin-top: 15rem;
  }
}

/* about page */
.trip-hero-section.about-page {
  padding: 120px 0 60px;
}

.trip-about-section {
  padding: 0rem 1rem 90px;
}
.trip-about-thumb {
  position: sticky;
  top: 150px;
}
.rounded-image {
  border-radius: 1.5rem;
}
.trip-about-content {
  text-align: right;
  margin-left: 64px;
}
.trip-about-content p{
  font-size: 18px;
  line-height: 24px;
  color: #614646;
}
.trip-cta-section {
  padding-bottom: 90px;
}
.trip-cta-wrap {
  background: rgba(7, 160, 195, 0.1);
  border-radius: 22px;
  padding: 25px;
  text-align: center;
}
.trip-cta-wrap h2{
  font-weight: 600;
  font-size: 40px;
  color: #0D2F29;
  margin-bottom: 40px;
}
.trip-cta-menu {
  margin-bottom: 55px;
}
.trip-cta-menu ul li{
  margin: 0 5px;
  display: inline-block;
}
.trip-cta-menu ul li a{
  font-weight: 600;
  font-size: 24px;
  color: #0D2F29;
}

.trip-social ul li{
  margin: 0 35px;
  display: inline-block;
}
.trip-social ul li:first-child{
  margin-left: 0;
}
.trip-social ul li:last-child{
  margin-right: 0;
}


</style>