<template>
	<div class="trip-gallery-section trip-section-padding" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fgallery-bg.png?alt=media&token=4d0b35f5-b6db-4a46-99b6-0a19f5ba7caa')">
		<div class="container">
			<div class="trip-default-column">
				<h3>טיולים מומלצים</h3>
				<div class="trip-gallery-wrap">
                    
                    <div
                        v-for="(image, index) in images"
                        :key="index"
                        :class="image.class">
              <router-link :to=image.route>
                            <div class="trip-gallery-item">
                                    <!-- <h1>{{image.route}}</h1> -->
                                    <img :src="image.svg">
                                    <div class="trip-gallery-content">
                                        <h5>{{image.text}}</h5>				
                                    </div>
                            </div>
              </router-link>
            </div>
          <div class="trip-center-button">
						<a 
                            class="hero-outline-btn active blue-bg"
                            @click="goToSerchattractionPage">
                            צפו במסלולי טיול
                        </a>
					</div>
				</div>
            </div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            images: [{
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
                    text: 'Flachau -  שבועיים באוסטריה',
                    route: '/single_ride_with_map/1',
                    class: 'collection-grid-item'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/kartina960_720.jpg?alt=media&token=ad8180aa-e8b9-448d-8252-0bc411b8c6f0',
                    text: 'Carinthia -  שבועיים באוסטריה',
                    route: '/single_ride_with_map/61',
                    class: 'collection-grid-item'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/ride72_homepage%20(1).jpg?alt=media&token=9cb847dd-3f58-44ae-b41e-438b2db42cda',
                    text: 'שבועיים באוסטריה- אזור זלצבורג וטירול',
                    route: '/single_ride_with_map/72',
                    class: 'collection-grid-item'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/ride66_homepage.jpg?alt=media&token=4c3517ad-1912-4d9d-af50-5f7332ae121f',
                    text: 'וינה וזלצבורג 11 ימים',
                    route: '/single_ride_with_map/66',
                    class: 'collection-grid-item'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F68_12_Aug_2021_12%3A20%3A51_GMT?alt=media&token=755f7389-c1ad-473a-a9e2-d280b998a3e8',
                    text: 'אוסטריה - זלצבורג ווינה ב-10 ימים',
                    route: '/single_ride_with_map/68',
                    class: 'collection-grid-item'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F183_27_Aug_2022_17%3A17%3A40_GMT?alt=media&token=ed9f07cf-e111-4805-92c6-e0456e2ef8ff',
                    text: 'טיול שבועיים לדרום פולין והרי הטטרה בסלובקיה',
                    route: '/single_ride_with_map/183',
                    class: 'collection-grid-item'
                },
                {
                    svg: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F79_28_Aug_2021_07%3A07%3A16_GMT?alt=media&token=42bb5161-cae9-42a8-a143-427cfb4337a9',
                    text: 'אוסטריה - זלצבורג ווינה ב-9 ימים',
                    route: '/single_ride_with_map/79',
                    class: 'collection-grid-item'
                },

            ]
        }
    },
    methods: {
    ...mapActions([ 'goToSerchattractionPage',
                     ]),

  },

}
</script>
<style scoped>
.nev-bar-bg {
    position:absolute;
}


.jumbotron {
  background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fheader.png?alt=media&token=350c3bd1-ef0e-4d52-99cf-465796ffae0b" ) no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 1200px) {
  .jumbotron {
    background-position: 100% 100% !important;
    background-size: cover;
    /* background-origin: padding-box !important; */
  }
}
@media only screen and (min-width: 1024px) {
  .jumbotron {
    background-position: 25% 100%;
    /* background-origin: padding-box !important; */
  }
}
@media only screen and (min-width: 768px) {
  .jumbotron {
    background-position: 30% center;
    /* background-origin: content-box; */
  }
}
@media only screen and (max-width: 425px) {
  .jumbotron {
    background-position: 25% center;
    /* background-origin: content-box; */
  }
}
.home_page_image h1{
  font-size:5.6rem;
  line-height:1.1;
  font-weight: 1000;
}
.home_page_image h2{
  font-size:4.6rem;
  font-weight: 800;
  line-height:1.1;
}
.home_page_image h3{
  font-size:2.3rem;
  line-height:1.1;
  font-weight: 700;
}
.home_page_who_it_works_text h2{
  font-size:2.8rem;
  line-height:1.1;
  font-weight: 900;
  color: black;
  padding: 2rem 0;
}
.light-bg {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(30px);
}

.custom-select {
  width: 100%;
  border: 0;
  padding: 10px 10px;
  background-color: transparent;
  border-bottom: 1px solid #929fba !important;
  border-radius: 0;
}
.lead-head {
  /* font-family: Assistant; */
  font-style: normal;
  font-weight: 800;
  font-size: 29px;
  line-height: 126.3%;
  text-align: right;
  letter-spacing: 0.045em;
  color: #0d2f29;
}
.lead-head-sm {
  font-weight: bold;
  font-size: 30px;
  line-height: 126.3%;
  letter-spacing: 0.045em;
  color: #0d2f29;
}
.lead-follow {
  /* font-family: Assistant; */
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 126.3%;
  text-align: right;
  letter-spacing: 0.045em;
  color: #0d2f29;
}
.lead-follow-sm {
  /* font-family: Assistant; */
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 126.3%;
  text-align: center;
  letter-spacing: 0.045em;
  color: #0d2f29;
}
@media (max-width: 575.98px) {
  .dropdown-width {
    width: 75% !important;
  }
}
.expand-arrow {
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' %3E%3Cpath d='M 44.988281 13.984375 C 44.726563 13.992188 44.476563 14.101563 44.292969 14.292969 L 25 33.585938 L 5.707031 14.292969 C 5.519531 14.097656 5.261719 13.992188 4.992188 13.988281 C 4.582031 13.992188 4.21875 14.238281 4.0625 14.613281 C 3.910156 14.992188 4 15.421875 4.292969 15.707031 L 24.292969 35.707031 C 24.683594 36.097656 25.316406 36.097656 25.707031 35.707031 L 45.707031 15.707031 C 46.003906 15.421875 46.09375 14.980469 45.9375 14.601563 C 45.777344 14.222656 45.402344 13.976563 44.988281 13.984375 Z' %3E%3C/path%3E%3C/svg%3E")
    no-repeat;
  padding: 0.4em;
}











@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@400;600;700&family=Rubik:wght@400;500;700&display=swap');


body {
  font-size: 22px;
  line-height: 24px;
  color: #494848;
  background-color:#fff;
  overflow-x: hidden;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Noto Sans Hebrew', sans-serif;
}

a, a:active, a:focus, a:active {
  color: #222222;
  text-decoration:none !important
}
input{
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
a:hover,
a:focus,
input:focus,
input:hover,
select:focus,
select:hover,
select:active,
textarea:focus,
textarea:hover,
button:focus {
  outline: none;
}
input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #999;
  display: inline-block;
  padding: 5px 10px;
}

dl {
  margin-bottom: 1.6em;
}
dt {
  font-weight: bold;
}
dd {
  margin-bottom: 1.6em;
}
table,
th,
td {
  border: 1px solid #eaeaea;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 1.6em;
  table-layout: fixed; /* Prevents HTML tables from becoming too wide */
  width: 100%;
}
caption,
th,
td {
  font-weight: normal;
  text-align: left;
}
th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}
td {
  border-width: 0 1px 1px 0;
}
th, td {
  padding: 0.4em;
}
img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

iframe {
  width: 100%;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
  color: #0D2F29;
  font-weight: 600;
  font-family: 'Noto Sans Hebrew', sans-serif;
}


p {
    font-size: 16px;
    line-height: 34px;
}
p:last-child{
    margin: 0;
}
ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
html {
  scroll-behavior: smooth;
}

/*---------------------------------------------------
HEADER SECTION 
----------------------------------------------------*/

header.trip-header-section {
  position: absolute;
  width: 100%;
  z-index: 99;
  padding: 35px 0;
  transition: all 0.4s;
}

img.mobile-logo {
  display: none;
}
a.trip-header-btn {
  display: inline-block;
  background-color: #E42424;
  font-size: 21px;
  font-weight: 500;
  color: #fff;
  border-radius: 50px;
  margin-right: 45px;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-header-social path{
  transition: all 0.4s;
}
header.trip-header-section.sticky-menu .trip-header-social path{
  fill: black;
}
.trip-menu-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.trip-menu-wraper {
  position: relative;
}
.main-menu {
  display: block;
  margin-right: 50px;
}
.main-menu ul li{
  margin: 0 25px;
  display: inline-block;
}
.main-menu ul li:first-child{
  margin-left: 0;
}
.main-menu ul li:last-child{
  margin-right: 0;
}
.main-menu ul li a{
  font-weight: 700;
  font-size: 18px;
  color: white;
  transition: all 0.4s;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
header.trip-header-section.sticky-menu .main-menu ul li a{
  color: black;
}

/* offcanvas menu */
.main-menu {
  display: block;
  order: 1;
}
.offcanves-menu{
    display: none;
}
.offcanves-menu {
  width: 300px;
  background: #ffffff;
  height: 100%;
  position: fixed;
  z-index: 111;
  padding: 62px 20px;
  margin-left: -300px;
  transition: .3s;
  padding-right: 0px;
  top: 0;
}
.offcanves-menu ul {
    padding-top: 20px;
}
.offcanves-menu ul li {
    position: relative;
    border-bottom: 1px solid #ddd;
    margin-right: 25px;
}
.offcanves-menu ul li:last-child {
    border-bottom: 0px;
}
.offcanves-menu ul li a {
    padding: 13px 0px;
    display: block;
    color: #333333;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.4s; 
}
.offcanves-menu ul li a:hover {
    color: #3066F0;
}
.offcanves-menu.active {
      margin-left: 0px;
      z-index: 1111;
}

.off-menu span.menubar {
      font-size: 28px;
      cursor: pointer;
      transition: all linear 0.3s;
      color: #000;
 }

.off-menu {
    float: right;
}

.close {
      position: absolute;
      right: 20px;
      top: 20px;
      color: #DF8A3E;
      font-size: 20px;
      padding: 5px 15px;
      border: 1px solid #DF8A3E;
      transition: all linear 0.3s;
      cursor: pointer;
}
  .close:hover {
        color: #DF8A3E;
        border-color: #DF8A3E;
}

.offcanvas-overlay {
      width: 100%;
      height: 100%;
      background: #000;
      position: fixed;
      z-index: 0;
      visibility: hidden;
      opacity: 0;
      transition: .5s;
 }

.offcanvas-overlay.active {
      opacity: .3;
      z-index: 111;
      visibility: visible;
 }

.menu-bar {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    color: #fff;
    font-size: 30px;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
}
header.trip-header-section.sticky-menu .menu-bar{
  color: black;
}


/* offcanvas menu */


/*---------------------------------------------------
Hero SECTION 
----------------------------------------------------*/
.trip-hero-section.home-page {
  position: static;
  background-size: cover;
  background-position: center;
  width: 100%;
  z-index: 9;
  transition: all 0.4s;
  min-height: 1115px;
  display: flex;
  align-items: center;
}

.home-page .trip-hero-content {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}
.home-page .trip-hero-content h1{
  color: #FFFFFF;
  font-weight: 700;
  font-size: 84px;
  line-height: 80px;
  margin: 0;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.home-page .trip-hero-content p{
  font-weight: 500;
  font-size: 29px;
  line-height: 34px;
  color: #FFFFFF;
  margin: 0 0 15px;
}
.trip-hero-newsletter {
  position: relative;
}
.trip-hero-newsletter input{
  height: 64px;
  width: 100%;
  padding: 20px!important;
  background: #FFFFFF;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  text-align: right;
  padding-right: 100px;
}
button#hero-newsletter-btn {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #494848;
  border: none;
  background-color: transparent;
  position: absolute;
  padding: 0;
  right: 30px;
  top: 50%;
  height: 64px;
  transform: translateY(-50%);
  font-family: 'Noto Sans Hebrew', sans-serif;

}
button#hero-newsletter-btn img{
  margin-left: 13px;
}
.hero-btn-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
a.hero-outline-btn {
  height: 44px;
  border: 1px solid #21AD96;
  border-radius: 22px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #21AD96;
  display: inline-block;
  padding: 10px 26px;
  transition: all 0.4s;
  font-family: 'Noto Sans Hebrew', sans-serif;

}
a.hero-outline-btn:hover{
  color: #fff;
  background-color: #21AD96;
}
a.hero-outline-btn.active{
  color: #fff;
  background-color: #21AD96;
}
a.hero-outline-btn.active.blue-bg {
  background-color: #08A0C3;
  border: 1px solid #08A0C3;
}
/* planner section */

.trip-planner-section {
  background-size: cover;
  background-position: center top;
  overflow: hidden;
}
.trip-section-padding{
  padding: 55px 0;
}
.trip-default-column {
  max-width: 1000px;
  margin: 0 auto;
}
.trip-default-column h3{
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 45px;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-planner-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.trip-planner-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 25px;
  flex: 0 0 37.5%;
  text-align: center
}
.trip-planner-card-thumb{
  margin-bottom: 30px;
}
.trip-planner-card-thumb img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 22px;
}
.trip-planner-card-data h4{
  font-weight: 700;
  font-size: 25px;
  color: #000000;
  margin-bottom: 20px;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-planner-card-data p{
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  font-family: 'Noto Sans Hebrew', sans-serif;
}

/* trip cta section */
.trip-cta3-section {
  background-size: cover;
  background-position-x: -40px;
  background-repeat: no-repeat;
}
.trip-cta3-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.trip-cta3-wrap {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  text-align: center;
  flex: 0 0 25%;
  padding: 25px;
}
.trip-cta3-data p{
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-cta3-thumb{
  margin-bottom: 30px;
}
.trip-center-button {
  text-align: center;
  margin-top: 44px;
}

/* gallery section */
.trip-gallery-section{
  background-size: cover;
  background-position: center;
}
.collection-grid-item.collection-grid-item-w2 {
  max-width: 100%;
}
.trip-gallery-item {
  position: relative;
  height: 320px;
  overflow: hidden;
  border-radius: 20px;
}
.trip-gallery-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: all 0.4s;
}
.trip-gallery-item img:hover{
  transform: scale(1.1)
}
.trip-gallery-content {
  background: #FFFFFF;
  border-radius: 12px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  text-align: center;
  padding: 10px;
}
.trip-gallery-content h5{
  font-weight: 500;
  font-size: 16px;
  color: #121212;
  margin: 0;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
/* slider section */
.trip-slider-section {
  background-position: center top;
  background-size: cover;
  padding: 55px 0 100px;
}
.trip-slider-column {
  background: #FFFFFF;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 25%);
  border-radius: 22px;
  padding: 30px 60px;
}
.trip-slider-item {
  display: flex!important;
}
.trip-slider-thumb {
  flex-shrink: 0;
  order: 1;
  flex: 0 0 60%;
}
.trip-slider-thumb[dir=rtl]{
  margin-left: 20px;
  order: 2;
}
.trip-slider-data {
  text-align: right;
  padding-left: 80px;
}
.trip-slider-data h4{
  font-weight: 500;
  font-size: 22px;
  color: #121212;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-slider-data p{
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-slider-bottom p{
  font-style: italic;
  margin: 0;
}
.trip-slider-bottom a.hero-outline-btn {
  height: 36px;
  font-size: 16px;
  padding: 5px 35px;
  margin-top: 15px;
}
.trip-slider .prev-arrow, 
.trip-slider .next-arrow {
  left: -105px;
  z-index: 9;
  top: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .4s;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Farrow-left.png?alt=media&token=d8035318-c149-4757-8812-128cb16c16bd");
}
.trip-slider .next-arrow {
  left: auto;
  right: -105px;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Farrow-right.png?alt=media&token=ef8a9aec-7e09-4ecc-b8b4-55c5ea65c425");
}
.trip-slider .slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  position: absolute;
  bottom: -85px;
  left: 50%;
  transform: translateX(-50%);
}
.slick-dots li{
  margin: 0 10px;
}
.trip-slider .slick-dots button {
  display: block;
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #98EDDF;
  text-indent: -9999px;
}
.trip-slider .slick-dots li.slick-active button {
  background-color: #21AD96;
}
.trip-map-red-icon {
  position: fixed;
  right: 20px;
  display: none;
  bottom: 20px;
  z-index: 9;
}

/* iconbox section */
.trip-iconbox2-section {
  background-position: center;
  background-size: cover;
}
.trip-iconbox-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.trip-iconbox2-wrap {
  background: #FFFFFF;
  border: 1px solid rgba(7, 160, 195, 0.2);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 22px;
  padding: 25px;
  text-align: center;
  flex: 0 0 40%;
}
.trip-iconbox2-wrap:nth-child(1) {
  margin-bottom: 80px;
}
.trip-iconbox2-wrap:nth-child(2) {
  margin-bottom: 80px;
}
.trip-iconbox2-thumb{
  margin-bottom: 15px;
}
.trip-iconbox2-data h4{
  font-weight: 700;
  font-size: 18px;
  color: #536271;
  margin-bottom: 10px;
}
.trip-iconbox2-data p{
  font-size: 16px;
  line-height: 24px;
  color: #536271;
}


/* brand section */
.trip-brand-section {
  background-color: #E6F6F9;
  padding: 30px 0;
}
.trip-brand-title h2{
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #536271;
  margin-bottom: 20px;
}

.trip-brand-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.trip-brand-item {
  flex: 0 0 25%;
  text-align: center;
}

/* footer section */
footer.trip-footer-section {
  padding: 35px 0;
  background: #0D2F29;
}
.trip-footer-wrap {
  text-align: center;
  position: relative;
}
.trip-footer-wrap img{
  margin-bottom: 20px;
}

.footer-menu ul li{
  padding: 0 25px;
  position: relative;
  display: inline-block;
}
.footer-menu ul li:first-child{
  padding-left: 0;
}
.footer-menu ul li:last-child{
  padding-right: 0;
}
.footer-menu ul li a{
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;

}

.footer-menu ul li:before{
  content: "";
  width: 5px;
  height: 5px;
  right: -4px;
  top: 13px;
  border-radius: 50px;
  position: absolute;
  background-color: #FFFFFF;
}


#button {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.3s ease 0s, opacity 0.5s ease 0s, visibility 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  cursor: pointer;
}
#button.show {
  opacity: 1;
  visibility: visible;
}

#button:active {
  background-color: #000;
}

.trip-map-red-icon {
  position: fixed;
  right: 20px;
  display: none;
  bottom: 20px;
  z-index: 9;
}







/*For bigger screen then normal 1500px desktop*/

@media (min-width: 1500px) {
  .container {
    max-width: 1360px;
  }
}

@media (min-width: 991px) {
    header.trip-header-section.sticky-menu {
        opacity: 0;
      }
      .trip-hero-section.sticky-menu{
        padding: 20px 0 0;
      }
  }

@media (min-width: 1200px) and (max-width: 1499px) {


}


@media only screen and (max-width: 1199px) {
    a.trip-header-social {
        display: none;
    }
    .main-menu ul li {
        margin: 0 15px;
    }
    .main-menu {
        margin-right: 30px;
    }
    .trip-sidebar{
        margin: 0;
    }

    .home-page.trip-hero-section {
        min-height: 800px;

    }
  
   

}



/*For Tablet Layout*/
@media(max-width: 991px) {
    .main-menu {
        display: none; 
    }
    .offcanves-menu{
        display: block;
    }
    .menu-bar{
        display: block;
    }
    .trip-menu-wrap {
        display: block;
        text-align: left;
        margin-left: 50px;
    }
    header.trip-header-section.home-page {
        padding: 18px 0;
        top: 0;
        background-color: white;
        position: fixed;
    }
    .home-page.trip-hero-section {
        min-height: 600px;
    }
    .home-page .trip-hero-content h1 {
        font-size: 50px;
        line-height: 50px;
    }
    /* planner section */
    .trip-planner-card {
        flex: 0 0 45%;
    }
    /* cta3 section */
    .trip-cta3-wrap {
        flex: 0 0 30%;
    }
    .trip-cta3-data p {
        font-size: 16px;
        line-height: 20px;
    }

    /* icon box */
    .trip-iconbox2-wrap {
        flex: 0 0 47%;
    }
    .trip-brand-item {
        flex: 0 0 50%;
        text-align: center;
    }
    /* slider section */
    .trip-slider-data {
        padding-left: 20px;
    }
    .trip-map-red-icon {
        display: block;
    }

}


/*For Wide Mobile Layout*/
@media (max-width: 767px) {
  
    .trip-logo {
        display: inline-block;
        width: 150px;
    }
    /* hero section */
    .home-page.trip-hero-section {
        min-height: auto;
        background-position: center left;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fhero-reponsive.png?alt=media&token=dbb45920-f05a-4bda-9c2f-781e8dca17b4")!important;
    }
    .home-page .trip-hero-content {
        max-width: 230px;
        padding-top: 110px;
        padding-bottom: 40px;
    }
    .home-page .trip-hero-content h1 {
        font-size: 20px;
        line-height: 22px;
    }
    .home-page .trip-hero-content p {
        margin: 0 0 10px;
        font-size: 12px;
        line-height: 12px;
        padding: 0 25px;
    }
    .trip-hero-newsletter input {
        height: 24px;
    }
    button#hero-newsletter-btn {
        top: 30%;
        transform: translate(-50%, -50%);
        right: auto;
        left: 50%;
        font-size: 14px;
    }
    button#hero-newsletter-btn img {
        width: 15px;
    }
    .hero-btn-wrap {
        position: static;
        transform: none;
        margin-top: 10px;
    }
    /* planner*/
    .trip-section-padding {
        padding: 50px 0;
    }
    .trip-planner-section{
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fplanner-responsive.png?alt=media&token=ab22f740-735d-4b2c-a96d-0a04d7d5b317")!important;
    }
    .trip-default-column {
        max-width: 215px;
    }
    .trip-default-column h3 {
        font-size: 14px;
        margin-bottom: 25px;
    }
    .trip-planner-wrap {
        display: block;
    }
    .trip-planner-card-data h4 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .trip-planner-card-data p {
        font-size: 10px;
        line-height: 12px;
    }
    a.hero-outline-btn {
        height: 25px;
        font-size: 14px;
        padding: 1px 22px;
    }
    a.hero-outline-btn.responsive-white {
        background-color: white;
        border: 1px solid white;
    }
    .trip-planner-card{
        margin-bottom: 30px;
    }
    .trip-planner-card:last-child{
        margin-bottom: 0;
    }

    /* trip cta */
    .trip-cta3-section {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fcta-responsive.png?alt=media&token=9592addf-6e1d-4632-b106-e3949403db5a")!important;
        background-size: contain;
        background-position-x: 16px;
        background-position-y: 51px;
        background-color: rgba(145, 214, 211, 0.1);
    }
    .trip-cta3-thumb {
        margin-bottom: 10px;
    }
    .trip-cta3-thumb img{
        width: 90px;
    }
    .trip-cta3-data p {
        font-size: 12px;
        line-height: 14px;
    }

    .trip-cta3-wrap {
        padding: 13px;
        margin-bottom: 25px;
        flex: 0 0 100%;
    }
    .trip-cta3-wrap:nth-child(1) {
        order: 3;
        margin: 0;
    }
    .trip-cta3-wrap:nth-child(2) {
        order: 2;
    }
    .trip-cta3-wrap:nth-child(3) {
        order: 1;
    }
    .trip-center-button {
        margin-top: 25px;
    }
    /* gallery section */
    .trip-gallery-section{
        background-image: url(https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fgallery-responsive.png?alt=media&token=7b8c6398-2258-4d68-b991-cfbccb10aadf)!important;
    }
    .trip-gallery-item {
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 20px;
        padding: 15px;
        margin: 0 0 15px;
    }
    .trip-gallery-item img{
        height: 102px;
    }
    .trip-gallery-content h5 {
        font-weight: 700;
        font-size: 14px;
    }
    .trip-gallery-content {
        padding: 10px 0 0;
        position: static;
        width: 100%;
    }
    .collection-grid-item:nth-child(3), 
    .collection-grid-item:nth-child(4), 
    .collection-grid-item:nth-child(6), 
    .collection-grid-item:nth-child(7) {
        display: none;
    }
    .collection-grid-item:nth-child(1),
    .collection-grid-item:nth-child(2),
    .collection-grid-item:nth-child(5) {
     flex: 0 0 100%;
}

   
    /* iconbox */
    .trip-iconbox2-section{
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ficonbox-bg-responsive.png?alt=media&token=a1fa9ecb-e906-4784-a520-df68e20a18f2")!important;
    }
   
    .trip-iconbox2-wrap:nth-child(1), 
    .trip-iconbox2-wrap:nth-child(2), 
    .trip-iconbox2-wrap:nth-child(3) {
        margin-bottom: 24px;
      }
      .trip-iconbox2-wrap:nth-child(1){
        order: 2;
      }
      .trip-iconbox2-wrap:nth-child(2){
        order: 1;
      }
      .trip-iconbox2-wrap:nth-child(3){
        order: 3;
        margin: 0;
      }
      .trip-iconbox2-wrap:nth-child(4){
        order: 2;
        margin-bottom: 24px;
      }
    .trip-iconbox2-thumb {
        width: 100px;
        margin: 0 auto 10px;
    }
    .trip-iconbox2-wrap {
        padding: 20px;
        flex: 0 0 100%;
    }
    .trip-iconbox2-data h4 {
        font-size: 14px;
        line-height: 22px;
    }
    .trip-iconbox2-data p {
        font-size: 12px;
        line-height: 22px;
    }

    /* slider */
    .trip-slider-section {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ftestimoni-bg-responsive.png?alt=media&token=26c1df4b-02ee-41bc-927d-0b3381cfd906")!important;
        background-position-y: 63px;
        background-color: rgba(145, 214, 211, 0.1);
        overflow: hidden;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 50px;
    }
    .trip-slider-column {
        padding: 20px;
    }
    .trip-slider-item {
        display: block!important;
    }
    .trip-slider-data{
        padding: 10px 0 0;
    }
    .trip-slider-data h4 {
        font-size: 12px;
    }
    .trip-slider-data p {
        font-size: 10px;
        line-height: 10px;
    }
    .trip-slider-bottom a.hero-outline-btn {
        height: 25px;
        font-size: 14px;
        padding: 0px 30px;
        margin-top: 15px;
    }
    .trip-slider .prev-arrow, .trip-slider .next-arrow {
        left: -65px;
    }
    .trip-slider .next-arrow {
        right: -65px;
        left: auto;
    }
    
    .trip-planner-card-thumb {
        margin-bottom: 15px;
    }
    
}

@media (max-width: 667px) {
.responsive-width-right {
    width: 70%;
}
.responsive-width-left {
    width: 30%;
}
/* footer */
footer.trip-footer-section {
    padding: 14px 0;
}
.footer-menu ul li {
    padding: 0;
    display: block;
    line-height: 1;
}
.footer-menu ul li a{
    font-size: 12px;
}
.footer-menu ul li:before {
    content: none;
}
.trip-footer-wrap img {
    margin-bottom: 9px;
    width: 110px;
}

}


/*For 320-384px up Mobile Layout*/
@media (max-width: 479px) {


}
@media (max-width: 400px){
    .trip-menu-wrap {
        margin-left: 25px;
    }
}

</style>

