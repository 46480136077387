<template>
<div class="position-relative overflow-hidden" dir="ltr">
    <AppNavbar/>
    <div>
      <img
        alt=""
        class="background-image"
        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>
    <ImageBanner :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fride-info-banner.jpg?alt=media&token=b765e21f-2604-4874-a029-3453357e4897'"
                 paragraph="פרטים כללים על הטיול והמלצות למשפחה שמעתיקה את הטיול שלכם"
                 size="240px" title="פרטי הטיול"/>
  <b-container>
    <b-row class="justify-content-center">
      <b-col col md="6">
        <b-form>
          <b-form-group
            v-if="this.isUserManager && !isFirstTimeAddingImages && !isRideInformationEditMode"
            id="label-details"
            class="text-right justify-content-end d-flex align-items-center"
            label="יצירת מסלול פרטי - המסלול יהיה חשוף אך ורק למשתמשים שתאשרו בעצמכם"
            label-for="checkbox-1">
          <b-form-checkbox
              id="checkbox-1"
              class="checkbox-right ml-2 checkbox-custom mb-2"
              name="checkbox-1"
              size="lg"
              v-model="publickRide"
              :unchecked-value=true
              :value=false>
          </b-form-checkbox>
          </b-form-group>
            <b-form-group
              v-if="!isFirstTimeAddingImages"
              id="input-group-1"
              class="text-right"
              :label=datePickerPlaceholder
              label-for="input-1"
          >


          <flat-pickr
            v-model="date"
            id="example-datepicker"
            ref="datePicker"
            class="form-control border-primary rounded-2 text-right py-1"
            :placeholder=datePickerPlaceholder
            :config=datePickerConfig
            style="direction: rtl;"
          >
          </flat-pickr>
          </b-form-group>
          <b-form-group
              id="label-title"
              class="text-right"
              label="הכותרת של הטיול"
              label-for="title">
            <b-form-input
                v-model="localRideInformationTitle"
                id="title"
                class="form-control border-primary rounded-2 text-right py-4"
                placeholder="שבועיים בטירול עם 3 ילדים בגילאים 10,7,4"
                required
                type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="label-age"
              class="text-right"
              label="הרכב גילאי המשתתפים"
              label-for="age">
            <DropdownWithSelect :col-size=4
                                placeholder="כמות המשתתפים לפי גילאים"
              >
              </DropdownWithSelect>
          </b-form-group>
          <b-form-group
              id="label-details"
              class="text-right"
              label="פרטים כללים על הטיול"
              label-for="albumLink">
            <b-form-textarea
                v-model="localRideInformationText"
                id="textarea"
                class="border-primary rounded-2 p-3"
                max-rows="6"
                placeholder=
                  "בחרנו לטייל בהולנד כי...
בחרנו לנחות בנמל התעופה סכיפהול כי...
התניידנו במהלך הטיול עם רכב שכור.
מומלץ להביא בגדים חמים ומעילי גשם.
בנוסף, הנה כמה המלצות שיכולות לשדרג את החוויה שלכם:"
                rows="4"
                style="direction: rtl;"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group
              id="label-details"
              class="text-right justify-content-end d-flex align-items-center"
              label="מתאים גם לאנשים עם מוגבלויות"
              label-for="checkbox-2">
            <b-form-checkbox
                id="checkbox-2"
                class="checkbox-right ml-2 checkbox-custom mb-2"
                name="checkbox-2"
                size="lg"
                v-model="physicalDisabilities"
                :unchecked-value=false
                :value=true>
            </b-form-checkbox>
          </b-form-group>
        </b-form>

        <PrimaryButton
          v-if="isFirstTimeAddingImages"
          class="w-100 mb-5"
          @click="this.startAddingTheAccommodations"
        >
          שמור והמשך להוספת מקומות לינה
        </PrimaryButton>
        <div  v-else-if ="isRideInformationEditMode" >
          <PrimaryButton class="w-100 mb-5" @click="this.updateRideInformation" >שמירה</PrimaryButton>
          <SecondaryButton
            :callBack="ReturnToSingleRide"
            class="w-100 mb-5"
          >
            חזרה לטיול ללא שמירה
          </SecondaryButton>
        </div>
        <PrimaryButton
          v-else
          @click="this.createRideInformation"
          class="w-100 mb-5"
        >
          המשך
        </PrimaryButton>
      </b-col>
    </b-row>
  </b-container>
  <AppFooter/>
</div>
</template>

<script>
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import AppNavbar from "@/components/Template/AppNavbar.vue";
import AppFooter from "@/components/Template/AppFooter.vue";
import DropdownWithSelect from "@/components/Buttons/DropdownWithSelect.vue";
import flatPickr from "vue-flatpickr-component";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RideInformation',
  components: {
    DropdownWithSelect,
    AppFooter,
    PrimaryButton,
    ImageBanner,
    AppNavbar,
    flatPickr,
    SecondaryButton,
    },
  computed: {...mapGetters([  'isUserManager',
                              'userRole',
                              'rideInformationTitle',
                              'rideInformationSherdPhotosAlbomLink',
                              'rideInformationText',
                              'rideInformationPhysicalDisabilities',
                              'rideInformationCheckIn',
                              'isRideInformationEditMode',
                              'isFirstTimeAddingImages',
                              'isAppLoggedIn',
                              'rideInformationPublick',
                              ]),
      datePickerConfig:{
        get(){
          if( this.isRideInformationEditMode )
            return { dateFormat:'d-m-Y' }
          return { mode: 'range', dateFormat:'d-m-Y' }
        }
      },
      datePickerPlaceholder:{
        get(){
          if( this.isRideInformationEditMode )
            return "תאריך התחלה של הטיול"
          return "תאריך התחלה וסיום"
        }
      },
      localRideInformationTitle:{
        get () {
          return this.rideInformationTitle
        },
        set (value) {
          this.$store.commit('setRideInformationTitle', value )
        }
      },
      
      localRideInformationText:{
        get () {
          return this.rideInformationText
        },
        set (value) {
          this.$store.commit('setRideInformationText', value )
        }
      },
      date:{
        get () {
          return this.rideInformationCheckIn

        },
        set (value) {
          this.$store.commit('setRideInformationCheckIn', value.split(' to ')[0])
          this.$store.commit('setRideInformationCheckOut', value.split(' to ')[1])

        },
      },
      physicalDisabilities:{
        get () {
          return this.rideInformationPhysicalDisabilities;
        },
        set (value) {
          const convertedValue = value ;
          this.$store.commit('setRideRideInformationPhysicalDisabilities', convertedValue)
        }
      },
      publickRide:{
        get () {
          return this.rideInformationPublick;
        },
        set (value) {
          const convertedValue = value ;
          this.$store.commit('setRideInformationPublick', convertedValue)
        }
      },
    },
    methods:
    {
    ...mapActions([ 'register',
                    'authenticate',
                    'validateAndupdateAppToken',
                    'createRideInformation',
                    'updateRideInformation',
                    'updateRideInformationWithoutPushingToRide',
                    'ReturnToSingleRide',
                    "showModal",
                    ] ),
    register () {
      this.$store.dispatch('register', { email: this.email, password: this.password })
        .then(() => this.$router.push('/'))
    },
    startAddingTheAccommodations() {
      this.updateRideInformationWithoutPushingToRide()
      this.$router.push('/add_accommodations_image')
    },
  },
  created() {
    if (!this.isAppLoggedIn) {
      this.showModal("pleaseLogInInOrderToEditRide");
    }
    if( this.isUserManager ){
      this.$store.commit('setRideInformationPublick', false)     
    }
  },
  watch: {
    // Watch the loggedIn value
    isAppLoggedIn() {
      if( this.isUserManager ){
        this.$store.commit('setRideInformationPublick', false)     
    }
    },
  },

};
</script>


<style scoped>
.custom-height {
  height: 50px; /* Adjust to desired height */
  line-height: 1.5; /* Ensures proper text alignment */
}

#checkbox-1 {
  display: flex;
  align-items: center;
}
#checkbox-2 {
  display: flex;
  align-items: center;
}

.form-control{
  background-color: white !important;
}
.dropdown-menu {
  margin-top: 6px;
  width: 100% !important;
}


</style>