<template>
  <div
    class="home"
    style="position:relative;"
    >
    <WhatsappChet/>
    <AddAtractionFromImage/>
    <AddAccommodationFromImage/>
    <div v-if="rideHeader" class="single_ride_header">
      <SingleRideHeader
        presentedText="RIDE_TITLE"
       />
    </div>
    <div class="whatsapp-icon-container-with map-container fix_iteam_right_doun_for_mobile">
      <a @click="goToMapRide(rideHeader.ride.id)" class="map-link">
        <img 
          class="map-icon"
          :src="require('@/assets/images/icons/open_map_icon.png')"
          alt="Open Map"
          >
        <span>תצוגת מפה</span>
      </a>
    </div>

    <div
      v-if="rideHeader.ride && !compareToUserId(rideHeader.ride.authorId)"
      class="ofer-importing-ride smale-one  ofer-import-ride-header">
      <h3>
        רוצים לערוך את הטיול הזה?
      </h3>
      <b-btn
          class="import-btm"
          variant="warning"
          @click="addRideToUser(rideHeader.ride.id)"
        >
          העתיקו וערכו
      </b-btn>
      <!-- <img
        class="import-btm"
        :src="require('@/assets/images/press_to_edit_explanation.png')"
        width="98rem"
        height="154rem"
        @click="addRideToUser(allRide.ride.id)"
      />
      </div> -->
    </div>
    <b-container 
      class="py-5"
      >
      <div class="single_ride_sumary">
        <SingleRideSumary />
      </div>
      <div 
      :class="{ 'hidden-at-load': !rideUploaded }"
      class="trip-main-section">
      <div 
        v-if="allRide&&allRide.ride"
      >
        <h3
          class="text-center main-ride-titles">
          תוכנית הטיול
        </h3>
      </div>

        <div class="container">
           <div class="single_ride_with_map_main_grid" >
             <SingleRide />
             <div >
               <div 
                class="header2 single_ride_map"
                v-if="allRide&&allRide.ride"
                >
                 <SingleRideMap />
               </div>
             </div>
           </div>
        </div>
      </div>
    </b-container>
    <div>
      <div v-if="rideUploaded">
        <PartnershipSection/>
        <HowItWorks/>
      </div>
    </div>
    <div
      v-if="rideUploaded"
      >
    <PageFooter
    />
    </div>
  </div>
</template>

<script>
import PageFooter from "@/components/PageFooter.vue";
import SingleRide from "@/components/SingleRide";
import SingleRideMap from "@/components/SingleRideMap";
import SingleRideHeader from "@/components/SingleRideHeader";
import SingleRideSumary from "@/components/SingleRideSumary";
import PartnershipSection from "@/components/PartnershipSection";
import HowItWorks from "@/components/HowItWorks";
import WhatsappChet from "@/components/WhatsappChet";
import AddAtractionFromImage from "@/components/AddAtractionButton";
import AddAccommodationFromImage from "@/components/AddAccommodationButton";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SingleRideWithMap",
  components: {
    SingleRide,
    SingleRideMap,
    PageFooter,
    SingleRideHeader,
    SingleRideSumary,
    PartnershipSection,
    HowItWorks,
    WhatsappChet,
    AddAtractionFromImage,
    AddAccommodationFromImage,

  },
  computed: mapGetters([  'allRide',
                          'rideHeader',
                          'isAppLoggedIn',
                          'compareToUserId',
                          'ridePresentedDay',
                          'rideUploaded' ]),
  methods:{
    ...mapActions([ 'addRideToUser',
                    'openLoginForm',
                    'rideUpdateMarkerByScrollerLocation',
                    'updateMainRideScroleLocation',
                    'serchAttractionToRide',
                    'userGuideForEditing',
                    'fetchRideHeaderInformation',
                    'fetchUserExtarInformationForRide',
                    'updateAttractionRideUploadedWorkAroundInOrderToDisplayTheMainRideAfterFulyLoaded',
                    ]),
    async goToMapRide( rideId ) {
      await this.rideUpdateMarkerByScrollerLocation(true);
      const dayNumber = this.ridePresentedDay;
      this.$router.push('/full_screen_single_ride_map/' + rideId + '/' + dayNumber )
    },
  },
  created() {
    const rideIdFromUrl = this.$route.params.id;
    if (rideIdFromUrl) {
      this.$store.commit("setLowedRideId", rideIdFromUrl);
    }
    this.fetchRideHeaderInformation()
    this.fetchUserExtarInformationForRide()
    window.addEventListener("scroll", this.rideUpdateMarkerByScrollerLocation);
    window.addEventListener('scroll', this.updateMainRideScroleLocation);
    window.addEventListener('scroll', this.userGuideForEditing);
    // window.addEventListener('scroll', this.updateAttractionRideUploadedWorkAroundInOrderToDisplayTheMainRideAfterFulyLoaded, { once: true });

  },
  watch: {
    isAppLoggedIn(newVal) {
      if( newVal && this.allRide){
        this.fetchRideHeaderInformation()
        this.fetchUserExtarInformationForRide()
    
      }
    },
  },
  mounted() {
    this.$store.commit("rideSetPresentedDay", null);
    //add the map stikiness
    const sectionHeroElMap = document.querySelector(".single_ride_sumary");
    const obsMap = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];
        // console.log( ent )
        if (ent.isIntersecting === false) {
          document.body.classList.add("map_sticky");
        }
        if (ent.isIntersecting === true) {
          document.body.classList.remove("map_sticky");
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "-90px",
      }
    );
    obsMap.observe(sectionHeroElMap);

  },
  destroyed() {
    window.removeEventListener('scroll',this.rideUpdateMarkerByScrollerLocation);
    window.removeEventListener('scroll', this.updateMainRideScroleLocation);
    window.removeEventListener('scroll', this.userGuideForEditing);
    window.removeEventListener('scroll', this.updateAttractionRideUploadedWorkAroundInOrderToDisplayTheMainRideAfterFulyLoaded, { once: true });
    // at the end of the firest time that the user adding this trip from images we should remove this condition
    this.$store.commit("setIsFirstTimeAddingImages", false);
  },

  metaInfo() {
    var canonicalUrl = `https://tripplanner.co.il${this.$route.fullPath}`;
    var rideTitle = 'TripPlanner';
    if ( this.rideHeader.ride){
      if (this.rideHeader.ride.title){
        rideTitle += ' - ' + this.rideHeader.ride.title;
      }
      if ( this.rideHeader.ride.is_based_ride){
        canonicalUrl=`https://tripplanner.co.il${this.$route.fullPath}`;
      }
      else{
        canonicalUrl="https://tripplanner.co.il/single_ride_with_map/" + this.rideHeader.ride.based_ride_id;

      }
    }
    return {
      title: this.$route.meta.title || rideTitle,
      link: [
        { property: 'og:title', content: rideTitle },
        // { property: 'og:description', content: 'Description of the Page' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://tripplanner.co.il${this.$route.fullPath}` },
        // { property: 'og:image', content: 'https://tripplanner.co.il/image-url.jpg' },
        { name: 'description', content: rideTitle },
        // { name: 'keywords', content: 'keyword1, keyword2, keyword3' },
        { rel: 'canonical', href: canonicalUrl }
      ]
    };
  }

};

</script>

