<template>
  <div
    class="image-container"
    @click="goTobookingLocal()"
>
    <img
      alt="Booking.com Search for Seasonal Deals"
      width="1792"
      height="300"
      src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fbooking_com%2FCopy%20of%20Early%202025%20Deals%20-%20image%207.jpg?alt=media&token=b378f125-479e-4548-92b6-b89ca1024554"
    >
    <div class="centered-text">
      <p>חסכו 15% על שהייה </p>
      <p>מבצע תחילת שנה ל-2025</p>
      <!-- <h1>15% הנחה</h1>
      <p>על הזמנת מלונות בבוקינג בחו"ל</p> -->
      <button class="order-button">למבצעים</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "BookingImageLabel",
  methods: {
    goTobookingLocal(  ) {
      if (window.fbq) {
          window.fbq('track', 'Lead');
          window.fbq('track', 'gooToBooking');
      }
      window.open('https://www.booking.com/dealspage.html?aid=2427332;campaign_id=early_year&label=sp_early_year&lang=he','_blank')
    },
  },

};

</script>

<style scoped>


.image-container:hover {
  cursor: pointer;
}


.image-container {
    position: relative;
    width: 100%;
    height: 300px; /* Set the height of the container */
    display: flex; /* Enable flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    overflow: hidden; /* Ensure any overflow is hidden */
}

.image-container img {
    max-width: 100%; /* Ensure the image scales within its container */
    max-height: 100%; /* Limit the image to the height of the container */
    object-fit: contain; /* Maintain aspect ratio and fit inside the container */
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #003b95; /* Text color */
    padding: 20px;
    box-sizing: border-box;
}
        .centered-text h1 {
            font-size: 32px; /* Change as needed for bigger size */
            margin: 10px 0;
            font-weight: bold; /* Make the text bold */
        }

        .centered-text p {
            font-size: 22px; /* Change as needed */
            margin: 5px 0;
            font-weight: bold; /* Make the text bold */
        }

        .order-button {
            margin-top: 20px;
            padding: 10px 20px;
            font-size: 18px;
            background-color: #003b95; /* Change as needed */
            color: white;
            border: none;
            border-radius: 5px; /* Add rounded corners */
            cursor: pointer;
            text-transform: uppercase;
            font-weight: bold; /* Make the button text bold */
        }

        /* Media query for mobile devices */
        @media (max-width: 840px) {
            .centered-text {
                width: 80%;
                height: 70%;
                padding: 10px;
            }

            .centered-text h1 {
                font-size: 24px;
            }

            .centered-text p {
                font-size: 18px;
            }

            .order-button {
                font-size: 16px;
                padding: 8px 16px;
            }
            .order-button {
                margin-top: 5px
            }

        }
        /* Media query for mobile devices */
        @media (max-width: 400px) {
            .centered-text {
                padding: 5px;
                margin: 5px 0;
            }
            .order-button {
                margin-top: 3px
            }
            .centered-text h1 {
                margin: 5px 0;
            }

        }

</style>
