<template>
<div class="position-relative overflow-hidden" dir="ltr">
    <head>
      <meta name="robots" content="noindex, nofollow" />
    </head>
    <AppNavbar/>
    <div>
      <img
        alt=""
        class="background-image"
        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>
    <ImageBanner :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fride-info-banner.jpg?alt=media&token=b765e21f-2604-4874-a029-3453357e4897'"
                 dir="rtl"
                 paragraph="התחברו או הירשמו כדי לצפות במסלול האישי שלכם."
                 size="240px" title="המסלול האישי שלך"/>
  <b-container>
    <b-row class="justify-content-center">
      <b-col col md="6">
        <b-form>
          <b-form-group
            id="label-title"
            class="text-right"
            label-for="title"
            dir="rtl"
          >
            <template
              v-if ="this.isAppLoggedIn"
              #label
            >
              <p>ברוכים הבאים למסלול האישי שלכם!</p>
              <p>עבור חשבון זה לא קיימת הרשאה למסלול זה. ניתן לרכוש את המסלול בקישור המצורף.</p>
            </template>
            <template
              v-else
              #label
            >
              <p>ברוכים הבאים למסלול האישי שלכם!</p>
              <p>כדי להיכנס ולצפות בפרטי המסלול, אנא התחברו או הירשמו.</p>
              <p>לאחר ההתחברות, תועברו אוטומטית לעמוד המסלול שלכם, שבו תמצאו את כל המידע שתכננו במיוחד עבורכם</p>            </template>
          </b-form-group>
        </b-form>
        <div  v-if ="this.isAppLoggedIn" >
          <PrimaryButton 
            class="w-100 mb-5"
            @click="goToRideRegistry"
          >
            רכישת המסלול 
          </PrimaryButton>
        </div>
        <PrimaryButton
          v-else
          @click="this.openLoginForm"
          class="w-100 mb-5"
        >
          להרשמה או התחברות
        </PrimaryButton>
      </b-col>
    </b-row>
  </b-container>
  <AppFooter/>
</div>
</template>

<script>
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import AppNavbar from "@/components/Template/AppNavbar.vue";
import AppFooter from "@/components/Template/AppFooter.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PrivatePageLanding',
  components: {
    AppFooter,
    PrimaryButton,
    ImageBanner,
    AppNavbar,
    },
  computed: {...mapGetters([  'isAppLoggedIn',
                              'redirectPageForUnauthorizedUsersByRideId',
                            ]),
    },
    methods:
    {
   ...mapActions([ 'openLoginForm',
                    'showModal',
                    'fetchRide',
                    'assignUserToLockedRide',
                    ] ),
    goToRideRegistry() {
      const redirectPage = this.redirectPageForUnauthorizedUsersByRideId(this.rideId)
      if (redirectPage) {
        window.open(redirectPage, '_blank');
      } else {
        console.error('Redirect page not found for the given ride ID.');
      }
      

    },
  },
  data() {
    return {
      rideId: null, // Global parameter to store the ride ID
    };
  },
  watch: {
    isAppLoggedIn(newVal) {
      if (newVal) {
        if (this.isAppLoggedIn && this.rideId) {
          this.$router.push('/single_ride_with_map/'+ this.rideId)
        }
      }
    },
  },
  created() {
    this.rideId = this.$route.params.ride_id;
  },

};
</script>


<style scoped>
</style>