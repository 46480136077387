<template>
<div class="position-relative overflow-hidden" dir="ltr">
    <head>
      <meta name="robots" content="noindex, nofollow" />
    </head>
    <AppNavbar/>
    <div>
      <img
        alt=""
        class="background-image"
        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>
    <ImageBanner :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fride-info-banner.jpg?alt=media&token=b765e21f-2604-4874-a029-3453357e4897'"
                 dir="rtl"
                 paragraph="התחברו או הירשמו כדי לצפות במסלול האישי שלכם."
                 size="240px" title="המסלול האישי שלך"/>
  <b-container>
    <b-row class="justify-content-center">
      <b-col col md="6">
        <b-form>
          <b-form-group
            id="label-title"
            class="text-right"
            label-for="title"
            dir="rtl"
          >
            <template #label>
              <p>ברוכים הבאים למסלול האישי שלכם!</p>
              <p v-if ="!isAppLoggedIn">כדי להיכנס ולצפות בפרטי המסלול, אנא התחברו או הירשמו.</p>
              <p>לאחר ההתחברות, לחצו על 'מעבר למסלול' כדי להגיע לעמוד המסלול שלכם, שבו תמצאו את כל המידע שתכננו במיוחד עבורכם.</p>
            </template>
          </b-form-group>
        </b-form>
        <div  v-if ="this.isAppLoggedIn" >
          <PrimaryButton 
            v-if ="shouldPresentTheCalenderbutten"
            class="w-100 mb-5 greenToBlue"
            @click="goToRideManagerColender()"
          >
            לקביעת פגישה נוחה להתאמת המסלול לצרכים שלי
          </PrimaryButton>

          <PrimaryButton 
            class="w-100 mb-5"
            @click="copyLink"
          >
            העתקת הלינק של המסלול לשימוש עתידי
          </PrimaryButton>

          <PrimaryButton 
            class="w-100 mb-5"
            @click="goToRide"
          >
            מעבר למסלול 
          </PrimaryButton>
        </div>
        <PrimaryButton
          v-else
          @click="this.openLoginForm"
          class="w-100 mb-5"
        >
          להרשמה או התחברות
        </PrimaryButton>
      </b-col>
    </b-row>
  </b-container>
  <AppFooter/>
</div>
</template>

<script>
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import AppNavbar from "@/components/Template/AppNavbar.vue";
import AppFooter from "@/components/Template/AppFooter.vue";
import { mapActions, mapGetters } from 'vuex';
import config from '@/common/config';

export default {
  name: 'AssignToRide',
  components: {
    AppFooter,
    PrimaryButton,
    ImageBanner,
    AppNavbar,
    },
  computed: {...mapGetters([  'isAppLoggedIn',
                              'userId'
                            ]),
    shouldPresentTheCalenderbutten(){
      if (this.context === 'consulting' && this.consultingMeetingCalendarLink){
        return true;
      }
      return false
    },
      
    },
    methods:
    {

    ...mapActions([ 'openLoginForm',
                    'showModal',
                    'fetchRide',
                    'assignUserToLockedRide',
                    'showModalWithParameters',
                    ] ),
    copyLink(){
      this.showModal( 'copyPrivateLink' )
    },
    goToRide() {
      this.$router.push('/single_ride_with_map/'+ this.rideId)

    },
    goToRideManagerColender() {
      if ( this.consultingMeetingCalendarLink ){
        window.open(
          this.consultingMeetingCalendarLink,
          '_blank' // Opens in a new tab
        );
      }
    }
  },
  props: ['context'],
  data() {
    return {
      rideId: null, // Global parameter to store the ride ID
      consultingMeetingCalendarLink: false, // Global parameter to store the ride ID
    };
  },
  watch: {
    isAppLoggedIn(newVal) {
      if (newVal) {
        if (this.isAppLoggedIn && this.rideId) {
          this.assignUserToLockedRide( this.rideId).then(() => {
            this.fetchRide();
          })
        }
      }
    },
  },
  created() {
    const rideKey = this.$route.params.ride_key;
    this.rideId = null;

    // copy this code to after log in
    if (rideKey in config.config.PRIVATE_RIDES_CONVERTER) {
      const rideData = config.config.PRIVATE_RIDES_CONVERTER[rideKey];
      this.rideId = rideData.rideId;
      if (rideData.consultingMeetingCalendarLink){
        this.consultingMeetingCalendarLink = rideData.consultingMeetingCalendarLink;
      }
    // Redirect the URL to the simplified version
      window.history.replaceState({}, '', `/single_ride_with_map/${this.rideId}`);

    }
    else{
      this.showModalWithParameters({
        errorNumber: 'the_private_link_is_not_valide',
        type: 'textConverter',
      });

    }

    // we do it in ordedr to allow the standart popup to get the information that it need, if we change the popup we can remove this one and we will change the popup
    if (this.rideId) {
      this.$store.commit("setLowedRideId", this.rideId);
      this.fetchRide();
    }
    if (this.isAppLoggedIn && this.rideId) {
      this.assignUserToLockedRide( this.rideId);
    }

  },

};
</script>


<style scoped>
  .greenToBlue{
    background: linear-gradient(45deg, #34D100, #07A0C3);
  }
</style>