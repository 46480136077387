
import api from '../../api/rideInformation';
import { router } from '../../main';

const state = {
  rideInformationSherdPhotosAlbomLink: '',
  rideInformationTitle: '',
  rideInformationText: '',
  rideInformationId: null,
  rideInformationMode: null,
  rideInformationCheckIn: null,
  rideInformationCheckOut: null,
  rideInformationNumbrOfAdoult: 0,
  rideInformationNumbrOfTeen: 0,
  rideInformationNumbrOfChild: 0,
  rideInformationPhysicalDisabilities: false,
  rideInformationPublick: true,
};

const getters = {
  rideInformationSherdPhotosAlbomLink: state => state.rideInformationSherdPhotosAlbomLink,
  rideInformationTitle: state => state.rideInformationTitle,
  rideInformationText: state => state.rideInformationText,
  rideInformationId: state => state.rideInformationId,
  rideInformationMode: state => state.rideInformationMode,
  rideInformationCheckIn: state => state.rideInformationCheckIn,
  rideInformationCheckOut: state => state.rideInformationCheckOut,
  isRideInformationEditMode: state => !!state.rideInformationId,
  rideInformationNumbrOfAdoult: state => state.rideInformationNumbrOfAdoult,
  rideInformationNumbrOfTeen: state => state.rideInformationNumbrOfTeen,
  rideInformationNumbrOfChild: state => state.rideInformationNumbrOfChild,
  rideInformationPhysicalDisabilities: state => state.rideInformationPhysicalDisabilities,
  rideInformationPublick: state => state.rideInformationPublick,
};

const actions = {
  rideInformationLoadDefault({ commit }) {
    commit('setRideInformationId', null);
    commit('setRideInformationTitle', '');
    commit('setRideInformationSherdPhotosAlbomLink', '');
    commit('setRideInformationText', '');
    commit('setRideInformationMode', null);
    commit('setRideInformationCheckIn', null);
    commit('setRideInformationCheckOut', null);
    commit('setRiderideInformationNumbrOfAdoult', 0);
    commit('setRideRideInformationNumbrOfTeen', 0);
    commit('setRideRideInformationNumbrOfChild', 0);
    commit('setRideRideInformationPhysicalDisabilities', false);
    commit('setRideInformationPublick', true);
  },

  // getDatePickarFormat( {} ){
  //   return "{ mode: 'range', dateFormat:'d-m-Y' }"
  // },
  async openRideInformationPage({ dispatch, rootState }, rideId=null ) {
    const { apptoken } = rootState.appauth;
    await dispatch('rideInformationLoadDefault');
    if (apptoken) {
      if ( rideId ){
        await dispatch( 'uploadRideInformation', rideId )
      }
      else{
        router.push('/add_images')
        return
      }
      router.push('/ride_information')
    }
    else{
      dispatch('openLoginForm');
    }
  },

  async uploadRideInformation({ commit, rootState }, rideId ) {
    const { apptoken } = rootState.appauth;
    const response = await api.getRideInformation(apptoken,
      rideId);
    const data = response.data.rideInformation;
    commit('setRideInformationId', rideId );
    commit('setRideInformationTitle', data.title);
    commit('setRideInformationSherdPhotosAlbomLink', data.photos_albom_link);
    commit('setRideInformationText', data.text);
    commit('setRideInformationCheckIn', data.start_date);
    commit('setRideInformationCheckOut', data.end_date);
    commit('setRiderideInformationNumbrOfAdoult', data.number_of_adult);
    commit('setRideRideInformationNumbrOfTeen', data.number_of_teen);
    commit('setRideRideInformationNumbrOfChild', data.number_of_child);
    commit('setRideRideInformationPhysicalDisabilities', data.physical_disabilities);

  },

  async updateRideInformationWithoutPushingToRide({ dispatch }) {
    await dispatch('updateRideInformationOnly');
  },

  async updateRideInformation({ getters, dispatch }) {    
    const rideInformationId = getters.rideInformationId; 
    await dispatch('updateRideInformationOnly');
    router.push('/single_ride_with_map/' + rideInformationId);
  },
  async updateRideInformationOnly({ state, rootState, getters, dispatch }) {
    var rideInformation = {}
    rideInformation['title'] = state.rideInformationTitle;
    rideInformation['photos_albom_link'] = state.rideInformationSherdPhotosAlbomLink;
    rideInformation['text'] = state.rideInformationText;
    rideInformation['checkIn'] = state.rideInformationCheckIn.toString();
    rideInformation['number_of_adult'] = state.rideInformationNumbrOfAdoult;
    rideInformation['number_of_teen'] = state.rideInformationNumbrOfTeen;
    rideInformation['number_of_child'] = state.rideInformationNumbrOfChild;
    rideInformation['physical_disabilities'] = state.rideInformationPhysicalDisabilities.toString();
    const rideInformationId = getters.rideInformationId;
    const { apptoken } = rootState.appauth;
    const response = await api.updateRideInformation(apptoken,
      rideInformationId,
      rideInformation);
    if( response.data.ServerMassageCode != "secsses" ){
      dispatch( 'showModalWithParameters',
                { errorNumber : response.data.ServerMassageCode,
                  type:'textConverter' 
                } );
    }
    await dispatch('rideInformationLoadDefault');
    
  },

  async createRideInformation({ state, rootState, dispatch }) {
    var rideInformation = {}
    rideInformation['title'] = state.rideInformationTitle;
    rideInformation['photos_albom_link'] = state.rideInformationSherdPhotosAlbomLink;
    rideInformation['text'] = state.rideInformationText;
    rideInformation['checkIn'] = state.rideInformationCheckIn.toString();
    rideInformation['checkOut'] = state.rideInformationCheckOut.toString();
    rideInformation['number_of_adult'] = state.rideInformationNumbrOfAdoult;
    rideInformation['number_of_teen'] = state.rideInformationNumbrOfTeen;
    rideInformation['number_of_child'] = state.rideInformationNumbrOfChild;
    rideInformation['physical_disabilities'] = state.rideInformationPhysicalDisabilities.toString();
    rideInformation['is_publick'] = state.rideInformationPublick.toString();
    
    const { apptoken } = rootState.appauth;
    const response = await api.setRideInformation(apptoken,
      rideInformation);
    if (response.statusText == "OK") {
      await dispatch('rideInformationLoadDefault');    // set the ride id to lowad
      router.push('/single_ride_with_map/' + response.data.rideId);
    }
    dispatch( 'lockShowModalOnce' );
  },
};

const mutations = {
  setRideInformationSherdPhotosAlbomLink: (state, rideInformationSherdPhotosAlbomLink) => {
    state.rideInformationSherdPhotosAlbomLink = rideInformationSherdPhotosAlbomLink;
  },
  setRideInformationTitle: (state, rideInformationTitle) => {
    state.rideInformationTitle = rideInformationTitle;
  },
  setRideInformationText: (state, rideInformationText) => {
    state.rideInformationText = rideInformationText;
  },
  setRideInformationCheckIn: (state, rideInformationCheckIn) => {
    state.rideInformationCheckIn = rideInformationCheckIn;
  },
  setRideInformationCheckOut: (state, rideInformationCheckOut) => {
    state.rideInformationCheckOut = rideInformationCheckOut;
  },
  setRideInformationId: (state, rideInformationId) => {
    state.rideInformationId = rideInformationId;
  },
  setRideInformationMode: (state, mode) => {
    state.rideInformationMode = mode;
  },
  setRiderideInformationNumbrOfAdoult: (state, rideInformationNumbrOfAdoult) => {
    state.rideInformationNumbrOfAdoult = rideInformationNumbrOfAdoult;
  },
  setRideRideInformationNumbrOfTeen: (state, rideInformationNumbrOfTeen) => {
    state.rideInformationNumbrOfTeen = rideInformationNumbrOfTeen;
  },
  setRideRideInformationNumbrOfChild: (state, rideInformationNumbrOfChild) => {
    state.rideInformationNumbrOfChild = rideInformationNumbrOfChild;
  },
  setRideRideInformationPhysicalDisabilities: (state, rideInformationPhysicalDisabilities) => {
    state.rideInformationPhysicalDisabilities = rideInformationPhysicalDisabilities;
  },
  setRideInformationPublick: (state, rideInformationPublick) => {
    state.rideInformationPublick = rideInformationPublick;
  },


};

export default {
  state,
  getters,
  actions,
  mutations
};
