<template>
  <div class="position-relative overflow-hidden" >
    <AppNavbar/>
    <div>
      <img alt="" class="background-image" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=493f0ef0-6c28-4446-b093-b978b6b722fc">
    </div>
    
    <ImageBanner :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fadd-images-banner.jpg?alt=media&token=9e2124e3-11a6-4df9-bae0-3054eca5ba9a'"
                 paragraph=
                 "יצירת טיול בצורה אוטומטית על ידי העלאת תמונות מהטיול"
                 size="240px" title="הכנסה אוטומטית של הטיול"/>
    <b-container>
      <b-row class="">
        <AddImageCard :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Faccomodation-illustration.svg?alt=media&token=a226080e-feba-4bbc-9669-192f9d1b007f'"
                      class="order-2 order-md-2"
                      paragraph=
                      "בשלב נפרד אנו נכניס את המקומות שאתם ממליצים לישון
                      אים המלון שלכם לא היה מומלץ אז כדי להגיד באיזה איזור מומלץ לישון
                      "
                      step-number="2"
                      title="הוספת מקום לינה"/>
        <AddImageCard :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fattraction-illustration.svg?alt=media&token=b799c3b2-0870-4432-8adb-8b27c922e1e9'"
                      class="order-1 order-md-1"
                      paragraph="כדאי ליצור את הטיול יש ללכת לאלבום התמונות בטלפון ולהעלות את התמונות מהטיול לפי הכללים הבאים:"
                      :paragraphSections="[
                        'יש להעלות תמונה אחת בלבד מכל אטרקציה.',
                        'מומלץ להעלות את כל התמונות בבת אחת.',
                        'חובה שהתמונות יכילו נתוני מיקום (GPS).',
                        'ודאו ששיתוף המיקום פעיל בעת העלאת התמונות.',
                        'התמונות יכולות לכלול כל מקום שתרצו להמליץ עליו למטיילים הבאים: מסעדה, שפת אגם, או כל מקום מעניין אחר.'
                      ]"
                      step-number="1"
                      title="הוספת אטרקציות"/>
      </b-row>
      
      
      <!-- <input type="file" id="file-input" multiple @change="onFileChange" class="hidden-input"> -->



      <div class="d-flex justify-content-center">
        <PrimaryButton @click="triggerFileInput">העלו את כל התמונות (אחת מכל אטרקציה)</PrimaryButton>
        <input 
          type="file" 
          id="file-input" 
          multiple 
          @change="onFileChange" 
          ref="fileInput" 
          style="display: none;" 
        />
      </div>
    </b-container>
    <!-- <AppFooter/> -->
  </div>
</template>

<script>
import { fileHandlingMixin } from "@/mixins/fileHandlingMixin";
import { mapActions, mapGetters } from "vuex";
import { UPLOAD_RIDE_STATES } from '@/constants/uploadRideStates.js'
import config from '@/common/config';

import AppNavbar from "@/components/Template/AppNavbar.vue"
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
import AddImageCard from "@/components/Cards/AddImageCard.vue";
// import AppFooter from "@/components/Template/AppFooter.vue"
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";


export default {
  name: 'AddAttractionsImage',
  mixins: [fileHandlingMixin],
  components: { PrimaryButton,
                // AppFooter,
                AddImageCard,
                ImageBanner,
                AppNavbar,
              },

  computed: {
    ...mapGetters([ 'atractionsMetadataList',
                    'isAppLoggedIn',
                  ]),
  },
  methods: {
    ...mapActions([ "createOrUpdateTripFromImagesAndGoToNextStep",
                    "callAllImagesApi",
                    "showModal",
                    "showModalWithParameters"]),

  triggerFileInput() {
    if (!this.isAppLoggedIn) {
      this.showModal("pleaseLogInInOrderToEditRide");
      return
    }
    this.$refs.fileInput.click(); // Triggers the hidden file input's click event
  },
  async callAllImagesApiAndcreateOrUpdateTripFromImagesAndGoToNextStep() {
    await this.callAllImagesApi();
    await this.createOrUpdateTripFromImagesAndGoToNextStep();
  },

  async onFileChange(event) {
      if (event.target.files.length > 0) {
        this.files = event.target.files;

        // Pass the createOrUpdateTripFromImagesAndGoToNextStep function as the callback
        this.uploadFiles(() => {
          // at this part we finished to upload all the files 
          // and we can call the API that will analize the images
          this.callAllImagesApiAndcreateOrUpdateTripFromImagesAndGoToNextStep();
        }, config.config.ATTRACTION_ROOT_PATH);
      
        

      } else {
        console.log("No files selected");
      }
    },
  },
  created() {
    const rideIdFromUrl = this.$route.params.ride_id;
    if (rideIdFromUrl) {
      this.$store.commit("setIsFirstTimeAddingImages", false);
      this.$store.commit("setUploadRideIdFromImage", rideIdFromUrl);
    } else {
      this.$store.commit("setIsFirstTimeAddingImages", true);
      this.$store.commit("setUploadRideIdFromImage", null);
    }
    this.$store.commit("setUploadRideState", UPLOAD_RIDE_STATES.UPLOADING_ATRACTIONS_IMAGE );
  },
};

</script>

<style scoped>

</style>