<template>
  <div class="home">
    <WhatsappChet/>
    <NavBar/>
    <div id="sticky-menu2" class="trip-hero-section home-page" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fhero-bg-home.png?alt=media&token=521b270d-2385-4e0e-b8e8-95cf55ba6cd6')">
      <div class="container">
          <div class="trip-hero-content">
            <h1>
              טסים לחו"ל?
              <br>
              צפו במאגר הטיולים
              </h1>
              <p>משפחות משתפות טיולים - אתם בוחרים מה מתאים לכם</p>
              <div dir="ltr">
                <div class="trip-hero-newsletter">
                  <input type="text">
                  <button 
                    id="hero-newsletter-btn"
                    type="submit">
                    <!-- this is the auto complitebuttom, can you help me to make it loke right -->
                    <gmap-autocomplete
                      @place_changed="searchRidesByGeoPoint"
                      :select-first-on-enter="true"
                      v-on:input="handleInput"
                      class="search_bar_text light-bg border-1 text-right "
                      placeholder="?לאן טסים"
                      :options="{
                        fields: ['geometry'],
                      }"
                    >
                    </gmap-autocomplete>
                    <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fplne.png?alt=media&token=2317d0bc-4142-427c-9425-ada4933f9eaa" 
                      alt="">
                  </button>
                  <div class="hero-btn-wrap">
                    <a 
                      class="hero-outline-btn responsive-white"
                      @click="internalOpenRideInformationPage()">
                      שיתוף טיול
                    </a>
                    <a 
                      class="hero-outline-btn active"
                      @click="goToSearchRideByName()">
                      לצפיה
                    </a>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
			<!-- End hero section -->

			<div class="trip-planner-section trip-section-padding" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fplanner-bg.png?alt=media&token=e2d258a1-f155-4e01-8dda-a26db88fe12c')">
				<div class="container">
					<div class="trip-default-column">
						<h3>מה אפשר לעשות בTrip Planner</h3>
						<div class="trip-planner-wrap">
							<div class="trip-planner-card">
								<div class="trip-planner-card-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fplanner1.png?alt=media&token=cd405a35-3074-4e41-a3ef-2d9340ebc232" alt="">
								</div>
								<div class="trip-planner-card-data">
									<h4>שתפו טיול</h4>
									<p>
                    תכננתם טיול אדיר?
                    שתפו אותו
                  </p>
									<!-- why this one is not abuttin ( one that react when i put the mose over?) -->
                  <a
                    class="hero-outline-btn active"
                    @click="internalOpenRideInformationPage()">
                    שתפו טיול
                  </a>
								</div>
							</div>
							<div class="trip-planner-card">
								<div class="trip-planner-card-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fplanner2.png?alt=media&token=d1a6fe6d-325c-44c3-a881-fdd32f557af6" alt="">
								</div>
								<div class="trip-planner-card-data">
									<h4>תכננו טיול</h4>
									<p>
                    אחרים כבר 
                    שברו ת'ראש
                    במקומכם,
                    העתיקו וערכו
                  </p>
									<a 
                    class="hero-outline-btn active"
                    @click="goToSerchattractionPage()">
                    צפיה בטיולים
                  </a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
      <!-- End hero section -->
			<!-- end cta section -->
      <instruction-card />
      
      <LocationCard />

			<div class="trip-slider-section" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fslider-bg.png?alt=media&token=f7abf92b-7c05-47c4-8bf6-047e5f90ceb1')">
				<div class="container">
					<div class="trip-default-column">
						<h3>טיולים ששותפו לאחרונה</h3>
						<div class="trip-slider-column">
              <b-carousel
                id="carousel-latest_rides"
                :interval="5000"
                controls
                indicators                
                >
                <b-carousel-slide v-for="(latestRide, idx) in this.latestRides" :key="idx">
                  <template v-slot:img>
                    <div class="trip-slider-item">
                      <div class="trip-slider-thumb">
                        <img :src="latestRide.imagePath" alt="">
                      </div>
                      <div class="trip-slider-data">
                        <h4>{{latestRide.header}}</h4>
                        <p>{{latestRide.openText}}</p>
                        <div class="trip-slider-bottom">
                          <p>{{latestRide.sharedBy}}</p>
                          <p>{{latestRide.dates}}</p>
                          <!-- <a 
                            class="hero-outline-btn active"
                            :href="latestRide.route">צפיה בטיול</a> -->
                        </div>
                      </div>
                    </div>
                  </template>
                </b-carousel-slide>
              </b-carousel>
						</div>
					</div>
					
				</div>
			</div>
			<!-- End slider section -->

			<div class="trip-iconbox2-section trip-section-padding" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ficon-box-bg.png?alt=media&token=6ce38e00-c3bd-4c3f-9204-2bc81590e354')">
				<div class="container">
					<div class="trip-default-column">
						<h3>למה לבחור בTrip Planner </h3>
						<div class="trip-iconbox-column">
							<div class="trip-iconbox2-wrap">
								<div class="trip-iconbox2-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ficonbox3.png?alt=media&token=8c110121-d2cc-4a7d-a28b-24fb38a6eb61" alt="">
								</div>
								<div class="trip-iconbox2-data">
									<h4>כל הטיולים בוצעו ע"י מטיילים אחרים</h4>
									<p>אין כמו המלצות ממטיילים שכבר עשו את המסלול, כל
										המסלולים בוצעו ע"י מטיילים</p>
								</div>
							</div>
							<div class="trip-iconbox2-wrap">
								<div class="trip-iconbox2-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ficonbox4.png?alt=media&token=22d7bc79-9115-49ae-9c95-069501db43cc" alt="">
								</div>
								<div class="trip-iconbox2-data">
									<h4>מאגר גדול של אטרקציות וטיולים</h4>
									<p>בסיס גדול של טיולים ושל אטרקציות שאנשים
										עשו ובוחרים לשתף</p>
								</div>
							</div>
							<div class="trip-iconbox2-wrap">
								<div class="trip-iconbox2-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ficonbox1.png?alt=media&token=a4cdf0e6-8061-4a84-b041-8cc16864b23a" alt="">
								</div>
								<div class="trip-iconbox2-data">
									<h4>הזמנה קלה ונוחה של מלונות
									</h4>
									<p>הזמנת מלונות לכל הטיול במקום אחד
									</p>
								</div>
							</div>
							<div class="trip-iconbox2-wrap">
								<div class="trip-iconbox2-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ficonbox2.png?alt=media&token=580654ae-976f-4490-ad81-3294abac2ddb" alt="">
								</div>
								<div class="trip-iconbox2-data">
									<h4>עריכה קלה של הטיול
									</h4>
									<p>ניתן להוסיף או להוריד אטרקציות לאחר בחירת טיול
									</p>
								</div>
							</div>
						</div>
						<div class="trip-center-button">
							<a
                class="hero-outline-btn active blue-bg"
                @click="goToSerchattractionPage()">
                צפו במסלולי טיול
              </a>
						</div>
					</div>
				</div>
			</div>
			<!-- End iconbox -->

			<div class="trip-brand-section">
				<div class="container">
					<div class="trip-brand-title">
						<h2>בשיתוף עם</h2>
					</div>
					<div class="trip-brand-wrap">
						<div class="trip-brand-item">
							<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fbrand1.png?alt=media&token=b507c07f-8836-4722-a110-81f52605f5a9" alt="">
						</div>
						<div class="trip-brand-item">
							<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fbrand2.png?alt=media&token=115ea4a8-3d59-4b3f-afda-2bea9fb7af83" alt="">
						</div>
						<div class="trip-brand-item">
							<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fbrand3.png?alt=media&token=4fc288e6-9578-4031-8c0d-f6e5b2ddbe1f" alt="">
						</div>
						<div class="trip-brand-item">
							<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fbrand4.png?alt=media&token=6f46f408-d8ab-4e96-b6bf-3f6c22f2ed69" alt="">
						</div>
					</div>
				</div>
			</div>
			<!-- End brand section -->

			<footer class="trip-footer-section">
				<div class="container">
					<div class="trip-footer-wrap">
						<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ffooter-logo.png?alt=media&token=4123d21d-f76f-46af-9b8e-12a6fe8e09c0" alt="">
						<div class="footer-menu">
							<ul>
								<li><a href="">עמוד הבית</a></li>
								<li><a href="">אודות</a></li>
								<li><a href="">חיפוש טיולים</a></li>
								<li><a href="">תקנון</a></li>
								<li><a href="">יצירת קשר</a></li>
							</ul>
						</div>
						<a id="button"><img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ftop-btn.png?alt=media&token=9ea7ad2e-90c9-4974-b812-31a3f8450c8e" alt=""></a>
					</div>
				</div>
			</footer>
			<!-- emd footer -->
  </div>
</template>

<script>
// @ is an alias to /src
import InstructionCard from "@/components/InstructionCard.vue";
import LocationCard from "@/components/LocationCard.vue";
import { mapActions, mapGetters } from "vuex";
import NavBar from "@/components/NavBar.vue";
import WhatsappChet from "@/components/WhatsappChet";

export default {
  name: "HomePage",
  components: {
    InstructionCard,
    LocationCard,
    NavBar,
    WhatsappChet,
    // SearchBar: () => import("@/components/SearchBar.vue"),
  },
  data() {
    return {
      latestRides: [{
              imagePath: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
              header: 'Flachau -  שבועיים באוסטריה',
              openText: "אחרי אינספור חיפושים, קריאות וחקר, הצלחנו להרכיב את הטיול שיהיה מושלם עבור משפחתנו באוסטריה.\
                        החלטנו על אזור טיול (חבל זלצבורג) ועל מקום לינה שממנו יצאנו לטיול כוכב.\
                        מזג האוויר היה הפכפך. בוינה חם במהלך היום, קריר יותר במהלך הלילה, בחבל זלצבורג ובטיולים בהם עלינו להרים היה קר וגם גשום לעיתים.",
              route: '/single_ride_with_map/1',
              sharedBy: 'שותף ע״י אבשלום כץ',
              dates: '01.09.2019-20.08.2019',
          },
          {
              imagePath: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/ride72_homepage%20(1).jpg?alt=media&token=9cb847dd-3f58-44ae-b41e-438b2db42cda',
              header: 'שבועיים באוסטריה- אזור זלצבורג וטירול',
              openText: "הי, אני אשכר (ארבליך-בריפמן) ואני סופרת ילדים ונוער ומנחת סדנאות כתיבה אינטרנטיות לילדים.\
                        מעבר לכך, אני נשואה ואמא לשלוש בנות. המשפחה שלנו חובבת מושבעת של טיולים. בחרתי לחלוק איתכם ולשתף אתכם בטיולנו לאוסטריה.",
              route: '/single_ride_with_map/72',
              sharedBy: 'שותף ע״י אשכר ארבליך-בריפמן',
              dates: '26.07.2021-12.07.2021',
          },
          {
              imagePath: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F183_27_Aug_2022_17%3A17%3A40_GMT?alt=media&token=ed9f07cf-e111-4805-92c6-e0456e2ef8ff',
              header: 'שבועיים בדרום פולין והרי הטטרה בסלובקיה',
              openText: "טיול משפחתי שחיכינו לו הרבה זמן (הטיול הראשון מאז הקורונה).\
                         זוג הורים + ארבעה ילדים ביגלאי 4-11.\
                         אוהבים טבע, אטרקציות ומסלולים קלילים, כך שאחרי הרבה מחשבה החלטנו לטייל בדרום פולין ולשלב גיחות לסלובקיה (בפועל היינו שם שלושה ימים)...)\
                       ",
              route: '/single_ride_with_map/183',
              sharedBy: 'שותף ע״י אבשלום כץ ',
              dates: '25.08.2022-11.08.2022',
          },
      ],
      userSearchInput: "",
      gMapPlace: "",
      jumbotronHeight: "",
      alignLocation: "end",
      value1: 2,
      value2: 2,
      value3: 0,
      selectsOptions: {
        first: {
          label: { text: "ימים" },
          options: ["1", "2", "3", "4", "5", "6", "7", "14", "21"]
        },
        second: {
          label: { text: "םישנא" },
          options: ["choice 1", "choice 2", "choice 3"]
        },
        third: {
          label: { text: "םוקימ ןזה" },
          options: ["choice 1", "choice 2", "choice 3"]
        }
      },
      selects: [
        [{ text: "םימי", value: null }, "choice 1", "choice 2", "choice 3"],
        [{ text: "םישנא", value: null }, "choice 1", "choice 2", "choice 3"],
        [{ text: "םוקימ ןזה", value: null }, "choice 1", "choice 2", "choice 3"]
      ]
    };
  },
  computed: mapGetters(["isAppLoggedIn"]),
  methods: {
    ...mapActions(["openRideInformationPage",
                   "searchRidesByGeoPoint",
                   "goToSerchattractionPage",
                   "showModalWithParameters",
                   "fetchGlobalRidesByName",
                   ]),
    handleInput(event) {
      this.userSearchInput = event.target.value
    },
    goToSearchRideByName(){
      if(this.userSearchInput == ""){
        this.goToSerchattractionPage();
        return
      }
      const link = '/search_ride_by_name/' + this.userSearchInput
      this.$router.push(link)
    },
    internalOpenRideInformationPage(){
      this.$router.push('/insert_new_ride_from_google_photos')
    },
    getImageHeight(imageLoadedCb) {
      let image = new Image();
      image.onload = () => {
        imageLoadedCb(image.height);
      };
      image.src = "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fheader.png?alt=media&token=350c3bd1-ef0e-4d52-99cf-465796ffae0b"
    },
    getHeight(height) {
      this.jumbotronHeight = height + "px";
    },

  },
  mounted() {
    this.getImageHeight(this.getHeight);
    if( window.innerWidth > 768 ){
      this.alignLocation = 'end';
    }
    else{
      this.alignLocation = 'center';
    }
  },
  metaInfo() {
    return {
      title: 'TripPlanner',
      link: [
        { property: 'og:title', content: 'TripPlanner' },
        { property: 'og:description', content: 'מאגר טיול גדול של משפחות ששיתפו את המסלולים שלהם' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://tripplanner.co.il` },
        // { property: 'og:image', content: 'https://tripplanner.co.il/image-url.jpg' },
        { name: 'description', content: 'אתר לשיתוף טיולים וצפיה בטיולים של מטיילים ישראלים ברחבי העולם' },
        // { name: 'keywords', content: 'keyword1, keyword2, keyword3' },
        // { rel: 'canonical', href: "https://tripplanner.co.il" }
      ]
    };
  }
};
</script>

<style scoped>
.nev-bar-bg {
    position:absolute;
}

.after_page_header{
  margin-top: 10rem;
}

.light-bg {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(30px);
}


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@400;600;700&family=Rubik:wght@400;500;700&display=swap');


body {
  font-size: 22px;
  line-height: 24px;
  color: #494848;
  background-color:#fff;
  overflow-x: hidden;
  word-wrap: break-word;
  word-break: normal;
  font-family: 'Noto Sans Hebrew', sans-serif;
}

a, a:active, a:focus, a:active {
  color: #222222;
  text-decoration:none !important
}
input{
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
a:hover,
a:focus,
input:focus,
input:hover,
select:focus,
select:hover,
select:active,
textarea:focus,
textarea:hover,
button:focus {
  outline: none;
}
/* a:hover{
  text-decoration: none!important;
} */
input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #999;
  display: inline-block;
  padding: 5px 10px;
}

dl {
  margin-bottom: 1.6em;
}
dt {
  font-weight: bold;
}
dd {
  margin-bottom: 1.6em;
}
table,
th,
td {
  border: 1px solid #eaeaea;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 1.6em;
  table-layout: fixed; /* Prevents HTML tables from becoming too wide */
  width: 100%;
}
caption,
th,
td {
  font-weight: normal;
  text-align: left;
}
th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}
td {
  border-width: 0 1px 1px 0;
}
th, td {
  padding: 0.4em;
}
img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

iframe {
  width: 100%;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
  color: #0D2F29;
  font-weight: 600;
  font-family: 'Noto Sans Hebrew', sans-serif;
}


p {
    font-size: 16px;
    line-height: 34px;
}
p:last-child{
    margin: 0;
}
ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
html {
  scroll-behavior: smooth;
}

/*---------------------------------------------------
HEADER SECTION 
----------------------------------------------------*/

header.trip-header-section {
  position: absolute;
  width: 100%;
  z-index: 99;
  padding: 35px 0;
  transition: all 0.4s;
}

img.mobile-logo {
  display: none;
}
a.trip-header-btn {
  display: inline-block;
  background-color: #E42424;
  font-size: 21px;
  font-weight: 500;
  color: #fff;
  padding: 8px 81px 81px;
  border-radius: 50px;
  margin-right: 45px;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-header-social path{
  transition: all 0.4s;
}
header.trip-header-section.sticky-menu .trip-header-social path{
  fill: black;
}
.trip-menu-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.trip-menu-wraper {
  position: relative;
}
.main-menu {
  display: block;
  margin-right: 50px;
}
.main-menu ul li{
  margin: 0 25px;
  display: inline-block;
}
.main-menu ul li:first-child{
  margin-left: 0;
}
.main-menu ul li:last-child{
  margin-right: 0;
}
.main-menu ul li a{
  font-weight: 700;
  font-size: 18px;
  color: white;
  transition: all 0.4s;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
header.trip-header-section.sticky-menu .main-menu ul li a{
  color: black;
}

/* offcanvas menu */
.main-menu {
  display: block;
  order: 1;
}
.offcanves-menu{
    display: none;
}
.offcanves-menu {
  width: 300px;
  background: #ffffff;
  height: 100%;
  position: fixed;
  z-index: 111;
  padding: 62px 20px;
  margin-left: -300px;
  transition: .3s;
  padding-right: 0px;
  top: 0;
}
.offcanves-menu ul {
    padding-top: 20px;
}
.offcanves-menu ul li {
    position: relative;
    border-bottom: 1px solid #ddd;
    margin-right: 25px;
}
.offcanves-menu ul li:last-child {
    border-bottom: 0px;
}
.offcanves-menu ul li a {
    padding: 13px 0px;
    display: block;
    color: #333333;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.4s; 
}
.offcanves-menu ul li a:hover {
    color: #3066F0;
}
.offcanves-menu.active {
      margin-left: 0px;
      z-index: 1111;
}

.off-menu span.menubar {
      font-size: 28px;
      cursor: pointer;
      transition: all linear 0.3s;
      color: #000;
 }

.off-menu {
    float: right;
}

.close {
      position: absolute;
      right: 20px;
      top: 20px;
      color: #DF8A3E;
      font-size: 20px;
      padding: 5px 15px;
      border: 1px solid #DF8A3E;
      transition: all linear 0.3s;
      cursor: pointer;
}
  .close:hover {
        color: #DF8A3E;
        border-color: #DF8A3E;
}

.offcanvas-overlay {
      width: 100%;
      height: 100%;
      background: #000;
      position: fixed;
      z-index: 0;
      visibility: hidden;
      opacity: 0;
      transition: .5s;
 }

.offcanvas-overlay.active {
      opacity: .3;
      z-index: 111;
      visibility: visible;
 }

.menu-bar {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    color: #fff;
    font-size: 30px;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
}
header.trip-header-section.sticky-menu .menu-bar{
  color: black;
}


/* offcanvas menu */


/*---------------------------------------------------
Hero SECTION 
----------------------------------------------------*/
.trip-hero-section.home-page {
  position: static;
  background-size: cover;
  background-position: center;
  width: 100%;
  z-index: 9;
  transition: all 0.4s;
  min-height: 500px;
  display: flex;
  align-items: center;
}

.home-page .trip-hero-content {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}
.home-page .trip-hero-content h1{
  color: #FFFFFF;
  font-weight: 700;
  font-size: 84px;
  line-height: 80px;
  margin: 0;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.home-page .trip-hero-content p{
  font-weight: 500;
  font-size: 29px;
  line-height: 34px;
  color: #FFFFFF;
  margin: 0 0 15px;
}
.trip-hero-newsletter {
  position: relative;
}
.trip-hero-newsletter input{
  /* font-size: 14px; */
  height: 64px;
  width: 100%;
  padding: 20px!important;
  background: #FFFFFF;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
}
button#hero-newsletter-btn {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #494848;
  border: none;
  background-color: transparent;
  position: absolute;
  padding: 0;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Noto Sans Hebrew', sans-serif;

}
button#hero-newsletter-btn img{
  margin-left: 13px;
}
.hero-btn-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
a.hero-outline-btn {
  height: 44px;
  border: 1px solid #21AD96;
  border-radius: 22px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #21AD96;
  display: inline-block;
  padding: 10px 26px;
  transition: all 0.4s;
  font-family: 'Noto Sans Hebrew', sans-serif;

}
a.hero-outline-btn:hover{
  color: #fff;
  background-color: #21AD96;
}
a.hero-outline-btn.active{
  color: #fff;
  background-color: #21AD96;
}
a.hero-outline-btn.active.blue-bg {
  background-color: #08A0C3;
  border: 1px solid #08A0C3;
}
/* planner section */

.trip-planner-section {
  background-size: cover;
  background-position: center top;
  overflow: hidden;
}
.trip-section-padding{
  padding: 55px 0;
}
.trip-default-column {
  max-width: 1000px;
  margin: 0 auto;
}
.trip-default-column h3{
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 45px;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-planner-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.trip-planner-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 25px;
  flex: 0 0 37.5%;
  text-align: center
}
.trip-planner-card-thumb{
  margin-bottom: 30px;
}
.trip-planner-card-thumb img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 22px;
}
.trip-planner-card-data h4{
  font-weight: 700;
  font-size: 25px;
  color: #000000;
  margin-bottom: 20px;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-planner-card-data p{
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  font-family: 'Noto Sans Hebrew', sans-serif;
}

/* trip cta section */
.trip-cta3-section {
  background-size: cover;
  background-position-x: -40px;
  background-repeat: no-repeat;
}
.trip-cta3-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.trip-cta3-wrap {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  text-align: center;
  flex: 0 0 25%;
  padding: 25px;
}
.trip-cta3-data p{
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-cta3-thumb{
  margin-bottom: 30px;
}
.trip-center-button {
  text-align: center;
  margin-top: 44px;
}

/* gallery section */
.trip-gallery-section{
  background-size: cover;
  background-position: center;
}
.collection-grid-item.collection-grid-item-w2 {
  max-width: 100%;
}
.trip-gallery-item {
  position: relative;
  height: 320px;
  margin: 10px;
  overflow: hidden;
  border-radius: 20px;
}
.trip-gallery-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: all 0.4s;
}
.trip-gallery-item img:hover{
  transform: scale(1.1)
}
.trip-gallery-content {
  background: #FFFFFF;
  border-radius: 12px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  text-align: center;
  padding: 10px;
}
.trip-gallery-content h5{
  font-weight: 500;
  font-size: 16px;
  color: #121212;
  margin: 0;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
/* slider section */
.trip-slider-section {
  background-position: center top;
  background-size: cover;
  padding: 55px 0 100px;
}
.trip-slider-column {
  background: #FFFFFF;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 25%);
  border-radius: 22px;
  padding: 30px 60px;
}
.trip-slider-item {
  display: flex!important;
}
.trip-slider-thumb {
  flex-shrink: 0;
  order: 1;
}
.trip-slider-thumb[dir=rtl]{
  margin-left: 20px;
  order: 2;
}
.trip-slider-data {
  text-align: right;
  padding-left: 200px;
}
.trip-slider-data h4{
  font-weight: 500;
  font-size: 22px;
  color: #121212;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-slider-data p{
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  font-family: 'Noto Sans Hebrew', sans-serif;
}
.trip-slider-bottom p{
  font-style: italic;
  margin: 0;
}
.trip-slider-bottom a.hero-outline-btn {
  height: 36px;
  font-size: 16px;
  padding: 5px 35px;
  margin-top: 15px;
}
.trip-slider .prev-arrow, 
.trip-slider .next-arrow {
  left: -105px;
  z-index: 9;
  top: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .4s;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Farrow-left.png?alt=media&token=d8035318-c149-4757-8812-128cb16c16bd");
}
.trip-slider .next-arrow {
  left: auto;
  right: -105px;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Farrow-right.png?alt=media&token=ef8a9aec-7e09-4ecc-b8b4-55c5ea65c425");
}
.trip-slider .slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  position: absolute;
  bottom: -85px;
  left: 50%;
  transform: translateX(-50%);
}
.slick-dots li{
  margin: 0 10px;
}
.trip-slider .slick-dots button {
  display: block;
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #98EDDF;
  text-indent: -9999px;
}
.trip-slider .slick-dots li.slick-active button {
  background-color: #21AD96;
}
.trip-map-red-icon {
  position: fixed;
  right: 20px;
  display: none;
  bottom: 20px;
  z-index: 9;
}

/* iconbox section */
.trip-iconbox2-section {
  background-position: center;
  background-size: cover;
}
.trip-iconbox-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.trip-iconbox2-wrap {
  background: #FFFFFF;
  border: 1px solid rgba(7, 160, 195, 0.2);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 22px;
  padding: 25px;
  text-align: center;
  flex: 0 0 40%;
}
.trip-iconbox2-wrap:nth-child(1) {
  margin-bottom: 80px;
}
.trip-iconbox2-wrap:nth-child(2) {
  margin-bottom: 80px;
}
.trip-iconbox2-thumb{
  margin-bottom: 15px;
}
.trip-iconbox2-data h4{
  font-weight: 700;
  font-size: 18px;
  color: #536271;
  margin-bottom: 10px;
}
.trip-iconbox2-data p{
  font-size: 16px;
  line-height: 24px;
  color: #536271;
}


/* brand section */
.trip-brand-section {
  background-color: #E6F6F9;
  padding: 30px 0;
}
.trip-brand-title h2{
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #536271;
  margin-bottom: 20px;
}

.trip-brand-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.trip-brand-item {
  flex: 0 0 25%;
  text-align: center;
}

/* footer section */
footer.trip-footer-section {
  padding: 35px 0;
  background: #0D2F29;
}
.trip-footer-wrap {
  text-align: center;
  position: relative;
}
.trip-footer-wrap img{
  margin-bottom: 20px;
}

.footer-menu ul li{
  padding: 0 25px;
  position: relative;
  display: inline-block;
}
.footer-menu ul li:first-child{
  padding-left: 0;
}
.footer-menu ul li:last-child{
  padding-right: 0;
}
.footer-menu ul li a{
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;

}

.footer-menu ul li:before{
  content: "";
  width: 5px;
  height: 5px;
  right: -4px;
  top: 13px;
  border-radius: 50px;
  position: absolute;
  background-color: #FFFFFF;
}


#button {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.3s ease 0s, opacity 0.5s ease 0s, visibility 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  cursor: pointer;
}
#button.show {
  opacity: 1;
  visibility: visible;
}

#button:active {
  background-color: #000;
}

.trip-map-red-icon {
  position: fixed;
  right: 20px;
  display: none;
  bottom: 20px;
  z-index: 9;
}







/*For bigger screen then normal 1500px desktop*/

@media (min-width: 1500px) {
  .container {
    max-width: 1360px;
  }
}

@media (min-width: 991px) {
    header.trip-header-section.sticky-menu {
        opacity: 0;
      }
      .trip-hero-section.sticky-menu{
        padding: 20px 0 0;
      }
  }

@media (min-width: 1200px) and (max-width: 1499px) {


}


@media only screen and (max-width: 1199px) {
    a.trip-header-social {
        display: none;
    }
    .main-menu ul li {
        margin: 0 15px;
    }
    .main-menu {
        margin-right: 30px;
    }
    .trip-sidebar{
        margin: 0;
    }

    .home-page.trip-hero-section {
        min-height: 800px;

    }
  
   

}



/*For Tablet Layout*/
@media(max-width: 991px) {
    .main-menu {
        display: none; 
    }
    .offcanves-menu{
        display: block;
    }
    .menu-bar{
        display: block;
    }
    .trip-menu-wrap {
        display: block;
        text-align: left;
        margin-left: 50px;
    }
    header.trip-header-section.home-page {
        padding: 18px 0;
        top: 0;
        background-color: white;
        position: fixed;
    }
    .home-page.trip-hero-section {
        min-height: 600px;
    }
    .home-page .trip-hero-content h1 {
        font-size: 50px;
        line-height: 50px;
    }
    /* planner section */
    .trip-planner-card {
        flex: 0 0 45%;
    }
    /* cta3 section */
    .trip-cta3-wrap {
        flex: 0 0 30%;
    }
    .trip-cta3-data p {
        font-size: 16px;
        line-height: 20px;
    }

    /* icon box */
    .trip-iconbox2-wrap {
        flex: 0 0 47%;
    }
    .trip-brand-item {
        flex: 0 0 50%;
        text-align: center;
    }
    /* slider section */
    .trip-slider-data {
        padding-left: 20px;
    }
    .trip-map-red-icon {
        display: block;
    }

}


/*For Wide Mobile Layout*/
@media (max-width: 767px) {
  
    .trip-logo {
        display: inline-block;
        width: 150px;
    }
    /* hero section */
    .home-page.trip-hero-section {
        min-height: auto;
        background-position: center left;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fhero-reponsive.png?alt=media&token=dbb45920-f05a-4bda-9c2f-781e8dca17b4")!important;
    }
    .home-page .trip-hero-content {
        max-width: 230px;
        padding-top: 110px;
        padding-bottom: 40px;
    }
    .home-page .trip-hero-content h1 {
        font-size: 20px;
        line-height: 22px;
    }
    .home-page .trip-hero-content p {
        margin: 0 0 10px;
        font-size: 12px;
        line-height: 12px;
        padding: 0 25px;
    }
    .trip-hero-newsletter input {
        height: 24px;
    }
    button#hero-newsletter-btn {
        top: 27%;
        transform: translate(-50%, -50%);
        right: auto;
        left: 50%;
        font-size: 14px;
    }
    button#hero-newsletter-btn img {
        width: 15px;
    }
    .hero-btn-wrap {
        position: static;
        transform: none;
        margin-top: 10px;
    }
    /* planner*/
    .trip-section-padding {
        padding: 50px 0;
    }
    .trip-planner-section{
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fplanner-responsive.png?alt=media&token=ab22f740-735d-4b2c-a96d-0a04d7d5b317")!important;
    }
    .trip-default-column {
        max-width: 215px;
    }
    .trip-default-column h3 {
        font-size: 14px;
        margin-bottom: 25px;
    }
    .trip-planner-wrap {
        display: block;
    }
    .trip-planner-card-data h4 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .trip-planner-card-data p {
        font-size: 10px;
        line-height: 12px;
    }
    a.hero-outline-btn {
        height: 25px;
        font-size: 14px;
        padding: 6px 22px;
        margin: 0 4px;
    }
    a.hero-outline-btn.responsive-white {
        background-color: white;
        border: 1px solid white;
    }
    .trip-planner-card{
        margin-bottom: 30px;
    }
    .trip-planner-card:last-child{
        margin-bottom: 0;
    }

    /* trip cta */
    .trip-cta3-section {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fcta-responsive.png?alt=media&token=9592addf-6e1d-4632-b106-e3949403db5a")!important;
        background-size: contain;
        background-position-x: 16px;
        background-position-y: 51px;
        background-color: rgba(145, 214, 211, 0.1);
    }
    .trip-cta3-thumb {
        margin-bottom: 10px;
    }
    .trip-cta3-thumb img{
        width: 90px;
    }
    .trip-cta3-data p {
        font-size: 12px;
        line-height: 14px;
    }

    .trip-cta3-wrap {
        padding: 13px;
        margin-bottom: 25px;
        flex: 0 0 100%;
    }
    .trip-cta3-wrap:nth-child(1) {
        order: 3;
        margin: 0;
    }
    .trip-cta3-wrap:nth-child(2) {
        order: 2;
    }
    .trip-cta3-wrap:nth-child(3) {
        order: 1;
    }
    .trip-center-button {
        margin-top: 25px;
    }
    /* gallery section */
    .trip-gallery-section{
        background-image: url(https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fgallery-responsive.png?alt=media&token=7b8c6398-2258-4d68-b991-cfbccb10aadf)!important;
    }
    .trip-gallery-item {
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 20px;
        padding: 15px;
        margin: 0 0 15px;
    }
    .trip-gallery-item img{
        height: 102px;
    }
    .trip-gallery-content h5 {
        font-weight: 700;
        font-size: 14px;
    }
    .trip-gallery-content {
        padding: 10px 0 0;
        position: static;
        width: 100%;
    }
    .collection-grid-item:nth-child(3), 
    .collection-grid-item:nth-child(4), 
    .collection-grid-item:nth-child(6), 
    .collection-grid-item:nth-child(7) {
        display: none;
    }
   
    /* iconbox */
    .trip-iconbox2-section{
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ficonbox-bg-responsive.png?alt=media&token=a1fa9ecb-e906-4784-a520-df68e20a18f2")!important;
    }
   
    .trip-iconbox2-wrap:nth-child(1), 
    .trip-iconbox2-wrap:nth-child(2), 
    .trip-iconbox2-wrap:nth-child(3) {
        margin-bottom: 24px;
      }
      .trip-iconbox2-wrap:nth-child(1){
        order: 2;
      }
      .trip-iconbox2-wrap:nth-child(2){
        order: 1;
      }
      .trip-iconbox2-wrap:nth-child(3){
        order: 3;
        margin: 0;
      }
      .trip-iconbox2-wrap:nth-child(4){
        order: 2;
        margin-bottom: 24px;
      }
    .trip-iconbox2-thumb {
        width: 100px;
        margin: 0 auto 10px;
    }
    .trip-iconbox2-wrap {
        padding: 20px;
        flex: 0 0 100%;
    }
    .trip-iconbox2-data h4 {
        font-size: 14px;
        line-height: 22px;
    }
    .trip-iconbox2-data p {
        font-size: 12px;
        line-height: 22px;
    }

    /* slider */
    .trip-slider-section {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Ftestimoni-bg-responsive.png?alt=media&token=26c1df4b-02ee-41bc-927d-0b3381cfd906")!important;
        background-position-y: 63px;
        background-color: rgba(145, 214, 211, 0.1);
        overflow: hidden;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 50px;
    }
    .trip-slider-column {
        padding: 20px;
    }
    .trip-slider-item {
        display: block!important;
    }
    .trip-slider-data{
        padding: 10px 0 0;
    }
    .trip-slider-data h4 {
        font-size: 12px;
    }
    .trip-slider-data p {
        font-size: 10px;
        line-height: 10px;
    }
    .trip-slider-bottom a.hero-outline-btn {
        height: 25px;
        font-size: 14px;
        padding: 0px 30px;
        margin-top: 15px;
    }
    .trip-slider .prev-arrow, .trip-slider .next-arrow {
        left: -65px;
    }
    .trip-slider .next-arrow {
        right: -65px;
        left: auto;
    }
    
    .trip-planner-card-thumb {
        margin-bottom: 15px;
    }
    
}

@media (max-width: 667px) {
.responsive-width-right {
    width: 70%;
}
.responsive-width-left {
    width: 30%;
}
/* footer */
footer.trip-footer-section {
    padding: 14px 0;
}
.footer-menu ul li {
    padding: 0;
    display: block;
    line-height: 1;
}
.footer-menu ul li a{
    font-size: 12px;
}
.footer-menu ul li:before {
    content: none;
}
.trip-footer-wrap img {
    margin-bottom: 9px;
    width: 110px;
}

}


/*For 320-384px up Mobile Layout*/
@media (max-width: 479px) {


}
@media (max-width: 400px){
    .trip-menu-wrap {
        margin-left: 25px;
    }
}
</style>
