<script setup>

</script>

<template>
  <div 
    class="container-fluid bg-light-blue py-5 position-relative overflow-hidden"
    dir="ltr"
  >
    <div
        class="position-absolute"
        style="top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 0"
    >
      <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fairlines-draw.svg?alt=media&token=1b36f835-84c7-47e9-92c6-e71c20fe0a5f" alt="">
    </div>
    <div class="container">
      <h2 class="fw-bold text-center pt-3 pt-md-5">איך מתכננים חופשה חכם?</h2>
      <b-row class="row my-3 my-md-5">
        <b-col align-self="stretch" class="col-lg-4 mb-lg-0 order-3 order-lg-1 mb-3">
          <div class="card border-0 shadow-sm p-2 p-md-3 rounded-5 h-100">
            <div class="card-body d-flex flex-column text-end position-relative ">
              <div
                  class="step-number-badge position-absolute h4 rounded-circle d-flex justify-content-center align-items-center"
                  style="">
                3
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fstep3.svg?alt=media&token=3109d1ec-849e-4434-bd44-0515717d694a" alt="step 3"
                   class="image-how-it-works mb-3">
              <h4 class="fw-semibold text-right h4">
                צאו לחופשה בכיף <br>ושתפו חברים ומשפחה
              </h4>
              <p class="h6 mb-0 text-muted text-right">
                תהנו מחוויית חופשה מדהימה עם ליווי מהאתר במהלך הטיול. ותחזירו לקהילה על ידי הוספת החופשה שלכם למאגר באתר ושיתוף עם חברים ומשפחה
              </p>
            </div>
          </div>
        </b-col>
        <b-col align-self="stretch" class="col-lg-4 mb-lg-0 order-2 order-lg-2 mb-3">
          <div class="card border-0 shadow-sm p-2 p-md-3 rounded-5 h-100">
            <div class="card-body d-flex flex-column text-end gap-md-3 position-relative">
              <div
                  class="step-number-badge position-absolute h4 rounded-circle d-flex justify-content-center align-items-center"
                  style="">
                2
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fstep2.svg?alt=media&token=9b8aba8a-99c5-4952-b98b-24d593ee21af" alt="step 2"
                   class="image-how-it-works mb-3">
              <h4 class="fw-semibold text-right h4">
                תוסיפו או תורידו
                <br>
                אטרקציות לחופשה שבחרתם
              </h4>
              <p class="h6 mb-0 text-muted text-right">
                תהנו מתכנון קל ונוח על ידי התאמת החופשה שבחרתם והוספת פעילויות מומלצות מחופשות נוספות ששותפו באתר
              </p>
            </div>
          </div>
        </b-col>
        <b-col align-self="stretch" class="col-lg-4 mb-lg-0 order-1 order-lg-3  mb-3">
          <div class="card border-0 shadow-sm p-md-3 p-2 rounded-5 h-100">
            <div class="card-body d-flex flex-column text-end gap-md-3 position-relative">
              <div
                  class="step-number-badge position-absolute h4 rounded-circle d-flex justify-content-center align-items-center"
                  style="">
                1
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fstep1.svg?alt=media&token=3c66f4bb-ba14-423d-bf5a-e4c6ade381ef" alt="step 1"
                   class="image-how-it-works mb-3">
              <h4 class="fw-semibold text-right h4">
                בחרו חופשה שאהבתם 
                <br>
                כבסיס לתכנון
              </h4>
              <p class="h6 mb-0 text-muted text-right">
                התכנון שלכם מתחיל בבחירת חופשה מתוך המאגר באתר, שישמש כבסיס לתכנון החופשה שלכם
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center pb-3 pb-md-5 position-relative" style="z-index: 2!important;">
        <button 
          class="btn btn-primary px-5 py-3 rounded-pill fw-semibold h5 z-2"
          @click="goToSerchattractionPage()"
        >
          צפו בחופשות מוצלחות
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'HowItWorksNew',
  methods: {
  ...mapActions(["goToSerchattractionPage",
                ]),
  },

};
</script>

<style scoped>
.image-how-it-works {
  height: 150px !important;
}
</style>