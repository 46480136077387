<template>
<!-- this is for the new pages design -->
  <nav 
    id="top"
    class="navbar navbar-expand-lg bg-white fixed-top shadow-sm"
    dir="ltr"
  >
    <div class="container d-flex">
      <!-- Template toggler button -->
      <b-button v-b-toggle.navbarNav
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                class="d-lg-none d-sm-inline-block custom-outline-button"
                data-target="#navbarNav"
                data-toggle="collapse"
                type="button"
      >
        <b-icon icon="list"></b-icon>
      </b-button>

      <div class="d-flex justify-content-end">
        <a 
          class="navbar-brand d-sm-none d-block"
          href="/"
        >
          <img alt="" height="32px" :src="require('@/assets/images/Logo.svg')">
        </a>
      </div>

      <b-collapse id="navbarNav" class="collapse navbar-collapse justify-content-start ml-4">
        <ul class="navbar-nav">
          <li 
            v-if="isAppLoggedIn"
            class="nav-item text-center mr-md-4 m-0 my-auto"
          >
            <a 
              class="nav-link clickable" 
              data-section="connect" 
              @click="appLogOut"
            >
              התנתקות
            </a>
          </li>
          <li
            v-else
            class="nav-item text-center mr-md-4 m-0 my-auto"
          >
            <a 
              class="nav-link clickable" 
              data-section="connect" 
              @click="openLoginForm"
            >
              התחברות
            </a>
          </li>

          <li class="nav-item text-center m-0 my-auto">
            <a 
              class="nav-link text-primary"
              data-section="facebook"
              href='https://www.facebook.com/tripplanner.co.il'
              aria-label="goo to TripPlanner facebook page"
              target='_blank'
            >
              <i
                class="fa-brands fa-xl fa-facebook">
              </i>
            </a>
          </li>
        </ul>
      </b-collapse>
      <b-collapse id="navbarNav" class="collapse navbar-collapse justify-content-end ml-4">
        <ul class="navbar-nav">
          <li class="nav-item text-center mr-md-4 m-0 my-auto">
            <a class="nav-link clickable" data-section="trips" @click="goToLink('/search_ride')">צפיה בחופשות</a>
          </li>
          <li class="nav-item text-center mr-md-4 m-0 my-auto">
            <a class="nav-link clickable" data-section="about" @click="goToLink('/about')">אודות</a>
          </li>
          <li class="nav-item text-center mr-md-4 m-0 my-auto">
            <a class="nav-link clickable" data-section="contact" @click="goToLink('/')">דף הבית</a>
          </li>
          <li class="nav-item text-center mr-md-4 m-0 my-auto">
            <a class="nav-link clickable" data-section="contact" v-if="isAppLoggedIn" @click="goToLink('/user_rides')">הטיולים שלי</a>
          </li>
        </ul>
      </b-collapse>

      <a class="navbar-brand d-sm-block d-none" href="/"><img alt="" height="42px" :src="require('@/assets/images/Logo.svg')"></a>
    </div>
  </nav>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppNavbar',
  computed: mapGetters(['isAppLoggedIn',
                        'userName',
                        'email']),
  methods: { ...mapActions(['login',
                            'appLogOut',
                            'validateAndupdateAppToken',
                            'openLoginForm',
                            ]),
      goToLink( link ) {
      this.$router.push(link)
    },
  },
  created() {
    this.validateAndupdateAppToken();
  }
};
</script>

<style scoped>
.custom-outline-button {
  border: 1px solid #dcdcdc;
  color: #333;
  background-color: transparent;
}

.custom-outline-button:hover {
  background-color: #f8f9fa;
}

.custom-outline-button:active {
  color: #333 !important;
  background-color: #f8f9fa !important;
  border-color: #dcdcdc !important;
}



.clickable {
  cursor: pointer; /* Makes the cursor change to a pointer when hovered */
  text-decoration: none; /* Removes default underline for a cleaner look */
  color: #007bff; /* Optional: Gives a button-like color */
}

.clickable:hover {
  text-decoration: underline; /* Adds an underline effect on hover for feedback */
  color: #0056b3; /* Optional: Darkens the color slightly on hover */
}

</style>