import axios from 'axios';
import config from '../common/config';

export default {
  assignUserToLockedRide( token, rideId )
    {
      const formData = new FormData()
      formData.append('rideId', rideId);
      
      return axios.post(`${config.config.ROOT_URL}/add_user_to_ride_privacy_api`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },

  
   
};
