import api from '../../api/manageRidePrivacy';
import config from '@/common/config';

// import { router } from '../../main';
// import firebase from 'firebase'

const state = {
  // apptoken: window.localStorage.getItem('travel_app_token'),
  // email: '',
  // userName: window.localStorage.getItem('travel_app_user_name'),
};


const getters = {
  redirectPageForUnauthorizedUsersByRideId: () => (rideId) => {
    return Object.values( config.config.PRIVATE_RIDES_CONVERTER).find(
      (ride) => ride.rideId == rideId
    )?.redirectPageForUnauthorizedUsers || null;
  },
};

const actions = {
  async assignUserToLockedRide({ rootState, dispatch } , rideId ) {
    const { apptoken } = rootState.appauth;
    const response = await api.assignUserToLockedRide( apptoken, rideId );
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
  },
  async shouldPresentTheDates( { getters }){
    const allRide = await getters.allRide;
    const isItThRideHoner = await getters.compareToUserId( allRide.ride.authorId );
    const endDate = await allRide.ride.end_date;
    let rideEndTime;
    try {
      // Parse "DD-MM-YYYY" format
      const [day, month, year] = endDate.split("-").map(Number);
      rideEndTime = new Date(year, month - 1, day).getTime(); // JS months are 0-indexed
      if (isNaN(rideEndTime)) {
        console.error("Invalid date after parsing:", endDate);
        rideEndTime = 0; // Fallback to avoid runtime errors
      }
    } catch (error) {
      console.error("Error parsing end_date:", error);
      rideEndTime = 0; // Fallback in case of an exception
    }
    if (  getters.rideType === 'private' &&
          !isItThRideHoner  &&
          rideEndTime < Date.now() 
          ) {
        return false;
      }
    return true;
  },


};

const mutations = {
  // setEmail: (state, email) => {
  //   state.email = email;
  // },

};

export default {
  state,
  getters,
  actions,
  mutations
};
