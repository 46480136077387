<script>

import ImageCarousel from "@/components/Carousel/imageCarousel.vue";
import CollapseText from "@/components/CollapseText";

export default {
  components: { ImageCarousel,
                CollapseText,
              },
  props: {
    ride: {
      type: Object,
      required: true
    },
    goToRideCallBack: {
      type: Function,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imagePaths: {
        trip1: require('@/assets/images/Logo.svg'),
        trip2: require('@/assets/images/Logo.svg'),
        trip3: require('@/assets/images/Logo.svg'),
        trip4: require('@/assets/images/Logo.svg'),
      },
    }
  },
  // methods: {
  //   toggleContent() {
  //     const button = this.$refs.toggleButton; // Get the button element
  //     const cardContent = button.closest('.card-body').querySelector('.content');
  //     if (!cardContent) {
  //       console.error("Content element not found.");
  //       return;
  //     }

  //     // Toggle the class
  //     cardContent.classList.toggle('show-all-content');
  //     this.isExpanded = cardContent.classList.contains('show-all-content');

  //     // Update button text and styles
  //     button.textContent = this.isExpanded ? "Less" : "More Data";
  //     button.classList.add("color-primary"); // Ensure the class remains consistent
  //   }
  // }
};
</script>

<template>
  <div class="card border-0 shadow-sm p-md-2 rounded-5 mb-3">
    <div class="card-body">
      <b-row class="justify-content-center align-items-center">
        <b-col class="text-right order-2 order-md-1" md="7" sm="12" xs="12">
          <h4 
            class="fw-bold mb-3"
            style="direction: rtl;"
          >
            {{ ride.title }}</h4>

          <div  class="mb-0 fs-6  text-end text-muted">
            <CollapseText 
              class="attraction_information"
              :text="ride.text"
              :uniqueId="Math.random()"
            />
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div
                style="direction: rtl;"
                class="bg-light-blue d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center mr-2">
              {{ ride.duration }} ימים  
              <i class="fa-solid fa-calendar-days text-primary h5 mb-0 mr-2 icon-size"></i>
            </div>
            <!-- <div class="bg-light-orange d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center">
              <i class="fa-solid fa-map-pin text-orange h5 mb-0 mr-2 icon-size"></i> {{ location }}
            </div> -->
          </div>
          <div class="d-flex align-items-center mb-md-0 mb-2 justify-content-between w-auto mt-3">
            <div class="d-lg-block d-none w-100 mr-3">
              <button
                class="btn btn-primary px-4 py-3 rounded-pill fw-semibold h5 w-100"
                @click="goToRideCallBack(ride.id)"
              >
                צפיה במסלול החופשה

              </button>
            </div>
            <input :placeholder=ride.start_date 
                   aria-describedby="basic-addon1"
                   aria-label="startDate"
                   class="form-control text-right h-100 py-3 w-100 h6 rounded-pill bg-white border-primary"
                   type="text">
            <i class="h5 fa-solid fa-arrow-right text-primary mx-2 icon-size"></i>
            <input :placeholder=ride.end_date
                   aria-describedby="basic-addon1"
                   aria-label="endDate"
                   class="form-control text-right h-100 py-3 w-100 h6 rounded-pill bg-white border-primary"
                   type="text">
          </div>
          <div class="d-md-none d-inline">
            <button
              class="btn btn-primary w-100 px-5 py-3 rounded-pill fw-semibold fs-5"
              @click="goToRideCallBack(ride.id)">
              צפיה במסלול החופשה
            </button>
          </div>
        </b-col>
        <b-col class="mb-md-0 mb-3 order-1 order-md-2" md="5" sm="12" xs="12">
          <ImageCarousel :id=id :src=ride.rideImages></ImageCarousel>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped>

.icon-size{
  font-weight: 900!important;
  font-size: 1.5rem!important;
}
</style>