<!-- BaseFromImage.vue -->
<template>
  <div class="position-relative overflow-hidden" dir="ltr">
    <AppNavbar ref="navbar" />
    <div>
      <img alt="" class="background-image" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=493f0ef0-6c28-4446-b093-b978b6b722fc">
    </div>
    <b-container class="top-section">
      <div class="d-flex justify-content-between align-items-center pb-2 border-bottom gap-2">
        <div>
          <span class="btn btn-primary px-3 py-2 rounded-pill" dir="rtl">
            {{this.tripFromImagesLocationInsertionStage.currentStep}}
             מתוך 
            {{this.tripFromImagesLocationInsertionStage.totalLocationsSteps}}
            </span>
        </div>
        <h3 class="mb-0 h3 text-right font-weight-bold">
          הוספת אטרקציה
        </h3>
      </div>
      <b-row class="attraction-from_image-card-body mt-2 mt-md-4" dir="rtl">
        <b-col class="mb-4 mb-md-0" col md="12" sm="12" xl="5" xs="12">
          <div 
            ref="datePickerRange"
            class="d-flex align-items-center">
            <div
                class="h4 rounded-circle d-flex justify-content-center align-items-center section-bullet">
              <i class="fa-solid fa-magnifying-glass-location"></i>
            </div>
            <h4 class="mr-3 h4">זיהוי אוטומטי</h4>
          </div>
          <ImageCard
            v-if="this.atractionsMetadataListFirestItem &&
                  this.atractionsMetadataListFirestItem.url"
            ref="dateRangePicker"
            :backgroundImage="this.atractionsMetadataListFirestItem.url"
            :date="this.atractionsMetadataListFirestItem?.dateTaken || ''"
            :openAichosenPlace="this.atractionsMetadataListFirestItem.analytics?.openAichosenPlace?.imagePlace || null"
            :yesCallBack="() => InternallAddToRide({type: config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GOOGLE_PLACE_ID, data: { placeId: this.atractionsMetadataListFirestItem.analytics?.openAichosenPlace?.imagePlace?.id || null} })"
            :noCallBack="scrollToInserManualySection"
            :isAddingAccommodationsFromImages="isAddingAccommodationsFromImages"
            :datesDuraton="updatedDateDuraton"
            @update-dates-duraton="handleDateDuratonUpdate"

          >
          </ImageCard>
        </b-col>

        <b-col ref="targetSection" class="mt-2 mt-md-4" col md="12" sm="12" xl="7" xs="12">
          <div
            v-if="!(this.atractionsMetadataListFirestItem&&this.atractionsMetadataListFirestItem.location&&this.atractionsMetadataListFirestItem.location.latitude)"
            class="text-right flex-column d-flex gap-2"
          >
            <p class="text-muted mb-0">
              ** התמונה לא מכילה נתוני מיקום, ולכן לא נוכל לעזור באיתור המקום. יש לבחור את שם המקום באופן ידני.
            </p>
          </div>
          <div
            v-if="!(this.atractionsMetadataListFirestItem&&this.atractionsMetadataListFirestItem.dateTaken)"
            class="text-right flex-column d-flex gap-2"
          >
            <p class="text-muted mb-0">
              ** התמונה לא מכילה את זמן הצילום ולכן בסוף התהליך יש לשייך אותה ליום המתאים.
            </p>
          </div>
          <div class="d-flex align-items-center">
            <div
                class="h4 rounded-circle d-flex justify-content-center align-items-center section-bullet">
              <i class="fa-regular fa-hand-point-up"></i>
            </div>
            <h4 class="mr-3 h4">בחירת מקום ידנית</h4>
          </div>
          <div class="text-right flex-column d-flex gap-2">
            <p class="text-muted mb-0">
              ניתן לבחור את המקום על ידי לחיצה על המקום במפה שמציין את מיקום האטרקציה שצולמה או הכנסת את שם המקום בתיבת הטקסט
            </p>
            <div class="d-flex justify-content-center align-items-center p-3 bg-white rounded-5 shadow-sm">
              <div style="width: 100%; position: relative;">
                <GlobalMap
                  class="single_ride_map11"
                  passed_class="single_ride_map_temp"
                  @userPressedOnMap="handleMapPress"
                  >
                </GlobalMap>
              </div>
            </div>
            
            <div>
              <p class="text-muted mb-0">
                נא לבחור מקום מהרשימה או להכניס ידנית
              </p>
              <div class="input-group mb-0 my-md-3">
                <gmap-autocomplete
                  ref="googleAutocomplete"
                  @place_changed="editPlaceAndAddToRide"
                  :select-first-on-enter="true"
                  placeholder="הכניסו את שם המקום"
                  v-on:input="handleInputAddGooglePlaceForImage"
                  class="form-control text-right py-4 px-3 fs-5 rounded-pill border-0 bg-white shadow-sm"
                  :options="{ fields: ['place_id'] }"
                />
              </div>
              <div 
                class="input-group justify-content-end align-items-center d-flex"
                >
                <LocationLabelButton
                  v-for="(meta, index) in atractionsMetadataListFirestItem?.analytics?.googleNearbyatractionsdisplayName || []" :key="index"
                  :callBack="() => InternallAddToRide({ type: config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GOOGLE_PLACE_ID, data: { placeId: meta.id } })"
                  :locationData=meta 
                >
                </LocationLabelButton>
              </div>
              <div class="justify-content-end align-items-center d-flex flex-column flex-lg-row gap-2 mt-3">
                <SecondaryButton
                  :callBack="() => InternallAddToRide({})"
                  class="btn-mobile order-2 order-md-1"
                >
                  דלג על תמונה זו
                </SecondaryButton>

                <!-- add the image with the original location -->
                <WarningButtons
                  v-if="atractionsMetadataListFirestItem&&
                        atractionsMetadataListFirestItem.location&&
                        atractionsMetadataListFirestItem.location.latitude"
                  :callBack="() => InternallAddToRide({type: config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GEOLOCATION, data: { location: atractionsMetadataListFirestItem.location } })"
                  class="btn-mobile order-1 order-md-2"
                >
                  הוספת תמונה ועריכתה בהמשך
                </WarningButtons>
                <!-- add the image with the default location -->
                <WarningButtons
                  v-else-if="imageUploadTripDefaultPresentedLocation"
                  :callBack="() => InternallAddToRide({type: config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GEOLOCATION, data: { location: imageUploadTripDefaultPresentedLocation } })"
                  class="btn-mobile order-1 order-md-2"
                >
                  תמונה זו לא מכילה נתוני מיקום, הוספת תמונה ועריכתה בהמשך
                </WarningButtons>

              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UPLOAD_RIDE_STATES } from '@/constants/uploadRideStates.js'; // Assuming you have this constant imported
import config from '@/common/config';
import GlobalMap from './GlobalMap';
import { dmsToDecimal } from "@/common/geoUtils.js";

import AppNavbar from "@/components/Template/AppNavbar.vue";
// import AppFooter from "@/components/Template/AppFooter.vue";
import ImageCard from "@/components/Cards/ImageCard.vue";
import LocationLabelButton from "@/components/Buttons/LocationLabelButton.vue";
import WarningButtons from "@/components/Buttons/WarningButtons.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";


export default {
  components: {
    GlobalMap,
    AppNavbar,
    ImageCard,
    LocationLabelButton,
    WarningButtons,
    SecondaryButton,

  },
  props: {
    dateTitle: {
      type: String,
      required: true
    },
    addToRide: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      config: config.config,
    };
  },
  computed: {
    ...mapGetters([
      'atractionsMetadataList',
      'atractionsMetadataListFirestItem',
      'mapWereloded',
      'imageUploadTripDefaultPresentedLocation',
      'openAichosenPlace',
      'googleLocationsOptions',
      'uploadRideIdFromImage',
      'isFirstTimeAddingImages',
      'uploadRideState',
      'tripFromImagesLocationInsertionStage',
      'locationFromImageCheckIn',
      'locationFromImageCheckOut',
    ]),
    updatedDateDuraton:{
      get () {
        if( this.atractionsMetadataListFirestItem && this.atractionsMetadataListFirestItem.dateTaken ){
          return this.atractionsMetadataListFirestItem.dateTaken.split(' ')[0] + ' to ' + this.atractionsMetadataListFirestItem.dateTaken.split(' ')[0];
        }
        else {
          return '';
        }
      },
      set (value) {
        this.$store.commit('setLocationFromImageCheckIn', value.split(' to ')[0])
        this.$store.commit('setLocationFromImageCheckOut', value.split(' to ')[1])
      },
    },
    isAddingAccommodationsFromImages() {
      return this.uploadRideState === UPLOAD_RIDE_STATES.ADDING_ACCOMMODATIONS_FROM_IMAGES;
    },
    validateAccommodationDurationSeted(){
      return( this.locationFromImageCheckIn && this.locationFromImageCheckOut)
    }

  },
  watch: {
    atractionsMetadataListFirestItem() {
      this.updateTheMapByLocation();
    },
    mapWereloded(){
      this.updateTheMapByLocation();
    }
  },
  methods: {
    ...mapActions([
      'showModal',
      'openRideInformationPage',
      'showModalWithParameters',
      'setOneMarker',
      'fetchRide',
      'shiftAndReturnAtraction',
    ]),
    goToDatePicker() {
      // Call the child component's method
      this.showModalWithParameters({
        errorNumber: 'plese_add_the_accommodation_dates',
        type: 'textConverter',
      });
      this.$refs.dateRangePicker.focusDatePicker();
    },

    handleDateDuratonUpdate(value) {
      this.updatedDateDuraton = value; // Updates via the setter
    },

    scrollToInserManualySection() {
      const navbar = this.$refs.navbar;
      const section = this.$refs.targetSection; // Reference to the target section
      if (section && navbar) {
        const navbarHeight = navbar.$el.offsetHeight;
        const sectionOffset = section.offsetTop;
        
        window.scrollTo({
          top: sectionOffset - navbarHeight,
          behavior: 'smooth',
        });
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling effect
      });
    },
    
    handleMapPress(parameters) {
      if( parameters.placeId){
        this.showModalWithParameters({  inerModalParameters : 
                                          { callBack: this.InternallAddToRide,
                                            placeId: parameters.placeId 
                                          },
                                        type: 'doYouWantToAddThisPointToRide'
                                      });
      }

    },
    handleInputAddGooglePlaceForImage(event) {
      console.log('this place information is: ', event.target.value);
    },
    editPlaceAndAddToRide(place) {
      // at this part we are adding the information from the autocomplite box
      const placeStructure = {type: config.config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GOOGLE_PLACE_ID, data: {placeId: place.place_id}}     
      this.InternallAddToRide(placeStructure);
    },
    async InternallAddToRide(place) {
      // const placeStructure = {type: "place_id", data: {placeId: place.place_id}}     
      
      const autocompleteInput = this.$refs.googleAutocomplete.$el;
      if (this.uploadRideState === UPLOAD_RIDE_STATES.ADDING_ACCOMMODATIONS_FROM_IMAGES){
        if( place && Object.keys(place).length > 0 && !this.validateAccommodationDurationSeted ){
          this.goToDatePicker()
          if (autocompleteInput) {
            autocompleteInput.value = '';
          }
          return;
        }
      }
     
      const isLastItem = this.atractionsMetadataList.length === 1;
      if (Object.keys(place).length > 0) {
        if (isLastItem) {
          await this.addToRide(place);
        } else {
          this.addToRide(place);
        }
      } else {
        const removedElement = await this.shiftAndReturnAtraction();
        console.log('Place is empty, skipping ', removedElement);
      }

      if (this.atractionsMetadataList.length == 0) {
        if (this.isFirstTimeAddingImages && this.uploadRideState === UPLOAD_RIDE_STATES.ADDING_ATRACTIONS_FROM_IMAGES) {
          this.openRideInformationPage(this.uploadRideIdFromImage);
          this.showModalWithParameters({
            errorNumber: 'upload_trip_adding_text_stage',
            type: 'textConverter',
          });
        }
        else if (this.isFirstTimeAddingImages && this.uploadRideState == UPLOAD_RIDE_STATES.ADDING_ACCOMMODATIONS_FROM_IMAGES) {
          this.$router.push('/single_ride_with_map/' + this.uploadRideIdFromImage);
          this.showModalWithParameters({
            errorNumber: 'plese_add_the_activity_info',
            type: 'textConverter',
          });
        }
        else {
          this.$router.push('/single_ride_with_map/' + this.uploadRideIdFromImage);
        }
      }
      this.scrollToTop()
      if (autocompleteInput) {
          autocompleteInput.value = '';
      }
    },
    
    firestPlaceLat() {
      if(this.atractionsMetadataListFirestItem){
        const lat = dmsToDecimal(this.atractionsMetadataListFirestItem.location.latitude, this.atractionsMetadataListFirestItem.location.latitudeRef)
        if( lat ){
          return lat 
        }
        if (  this.atractionsMetadataListFirestItem.analytics?.openAichosenPlace?.imagePlace?.geoLocation?.latitude !== null &&
              this.atractionsMetadataListFirestItem.analytics.openAichosenPlace.imagePlace.geoLocation.longitude !== null) {
          const aiGessLocationLat = this.atractionsMetadataListFirestItem.analytics.openAichosenPlace.imagePlace.geoLocation.latitude
          return aiGessLocationLat;
        }

      } 
    },
    
    firestPlaceLng() {
      if(this.atractionsMetadataListFirestItem){
        const lng = dmsToDecimal(this.atractionsMetadataListFirestItem.location.longitude, this.atractionsMetadataListFirestItem.location.longitudeRef)
        if( lng ){
          return lng;
        }
        if (  this.atractionsMetadataListFirestItem.analytics?.openAichosenPlace?.imagePlace?.geoLocation?.latitude !== null &&
              this.atractionsMetadataListFirestItem.analytics.openAichosenPlace.imagePlace.geoLocation.longitude !== null) {
          const aiGessLocationLng = this.atractionsMetadataListFirestItem.analytics.openAichosenPlace.imagePlace.geoLocation.longitude
          return aiGessLocationLng;
        }
      } 
    },
    updateTheMapByLocation(){
      if ( this.atractionsMetadataListFirestItem && this.atractionsMetadataListFirestItem.location && this.firestPlaceLat() ){
        setTimeout(() => {
        this.setOneMarker( {  latPoint: this.firestPlaceLat(),
                              lngPoint: this.firestPlaceLng(),
                              name:"the new point",
                              zoomLevel: 16,} )
        }, 100);
      }
      else{
        setTimeout(() => {
        // this is work arownd to set it at the begining of the creation  
        this.setOneMarker({
          latPoint: dmsToDecimal(this.imageUploadTripDefaultPresentedLocation.latitude, this.imageUploadTripDefaultPresentedLocation.latitudeRef),
          lngPoint: dmsToDecimal(this.imageUploadTripDefaultPresentedLocation.longitude, this.imageUploadTripDefaultPresentedLocation.longitudeRef),
          name: "the new point",
          zoomLevel: 14,
        });
      }, 100);
      }

    }
    
  },
};
</script>

<style scoped>
.large-btn {
  font-size: 2rem;
  padding: 1.5rem 3rem;
  margin: 0 10px;
}

.custom-skip-btn {
  background-color: #ff5733; /* Custom orange color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-skip-btn:hover {
  background-color: #e74c3c; /* Darker color on hover */
}

.button-spacing {
  margin-bottom: 5px; /* Adjust the spacing as needed */
}

.single_ride_map11 {
    display: inline-block;
    position: relative; /* Relative positioning for child absolute positioning */
    max-width: 60em;
    width: 100%; /* Ensures it takes the parent's available width */

    height:353px;
}








.single_ride_map{
  width:40rem;
  height: 60rem;
  /* border: 1px solid rgba(0, 0, 0, 0.4); */
  box-sizing: border-box;
  position: static;
  z-index: 0;

}




.top-section {
  margin-top: 96px;
  padding-bottom: 24px;
}

.section-bullet {
  background-color: rgba(7, 160, 195, 0.2);
  color: #07A0C3;
  height: 42px;
  width: 42px;
  top: 16px;
  right: 16px;
  font-weight: 900;
}

.h4 {
  font-weight: 700;
}

.h2 {
  font-weight: 800;
}

.circle {
  height: 42px;
  width: 42px;
}

.gap-2 {
  gap: 16px;
}

.btn-mobile {
  width: auto;
}

@media (max-width: 830px) {
  .btn-mobile {
    width: 100%;
  }

  .gap-2 {
    gap: 6px;
  }
}

</style>