import axios from 'axios';
import config from '../common/config';

export default {
  fetchRide(token, lowedRideId) {
    return axios.get(`${config.config.ROOT_URL}/ride_attractions_api/${lowedRideId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  fetchRideHeader(token, lowedRideId) {
    return axios.get(`${config.config.ROOT_URL}/ride_header_api/${lowedRideId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
};
