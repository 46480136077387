<template>
  <div>
    <b-modal
      id="loadingIcon"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
      centered
    >
      <div class="d-flex align-items-center">
        <h2>
          טוען נתונים...
        </h2>
        <div
          class="spinner-border ml-auto"
          role="status"
          aria-hidden="true"
        ></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["value"],
  
  computed: {
    ...mapGetters(['allRide' ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}
</style>