<template>
  <footer class="footer px-4 bg py-5">
    <b-container>
      <!-- visible md and up -->
      <b-row
        align-v="center"
        class="justify-content-lg-between d-none d-md-flex"
      >
        <b-col>
          <div class="copyright text-center text-lg-left">
            <!-- social icons -->
            <a
              v-for="(icon, index) in socialIcons"
              :key="index"
              :href="icon.url"
              class="mr-2"
              target="_blank"
              ><img
                :src="require('@/assets/images/icons/' + icon.icon)"
                :width="icon.width"
                :height="icon.height"
            /></a>
            <!-- back to top button -->
            <a href="">
              <svg
                class="caretup rounded ml-auto mr-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                /></svg
            ></a>
          </div>
        </b-col>
        <b-col cols="8">
          <!-- navigation links -->
          <b-nav align="center" class="nav-footer justify-content-lg-center">
            <b-nav-item
              v-for="(item, index) in menuItems"
              :key="index"
              :to="item.url"
            >
              <small style="color: rgba(255, 255, 255, 0.9)" >
                {{ item.text }}
              </small>
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col>
          <!-- tripplanner logo -->
          <img 
            width="85"
            height="15"
            src="@/assets/images/icons/Tripplanner.svg" />
        </b-col>
        <b-col cols="12" class="mt-5">
          <!-- copyright -->
          <div class="copyright text-center">
            <small style="color: rgba(255, 255, 255, 0.25)">
              © {{ year }} Tripplanner - All rights reserved
            </small>
          </div>
        </b-col>
      </b-row>
      <!-- visible on mobile screens -->
      <b-row align-v="center" class="d-flex d-md-none text-center">
        <b-col cols="12" class="py-4">
          <!-- back to top button -->
          <a href="">
            <svg
              class="caretup left rounded ml-auto mr-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              /></svg
          ></a>
          <!-- tripplanner logo -->
          <img 
            width="85"
            height="15"
            src="@/assets/images/icons/Tripplanner.svg" />
        </b-col>
        <b-col cols="12" class="py-3">
          <!-- navigation links -->
          <b-nav
            vertical
            align="center"
            class="nav-footer justify-content-lg-center"
          >
            <b-nav-item
              v-for="(item, index) in mobileMenuItems"
              :key="index"
              :to="item.url"
            >
              <small style="color: rgba(255, 255, 255, 0.9)" >
                {{ item.text }}
              </small>
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col cols="12" class="py-3">
          <!-- social icons -->
          <a
            v-for="(icon, index) in socialIcons"
            :key="index"
            :href="icon.url"
            class="mx-4"
            ><img
              :width="icon.width"
              :height="icon.height"
              :src="require('@/assets/images/icons/' + icon.icon)"
          /></a>
        </b-col>
        <b-col class="py-5">
          <!-- copyright -->
          <div class="copyright text-center">
            <span style="color: rgba(255, 255, 255, 0.25)">
              ©2020 Tripplanner - <br />
              All rights reserved
            </span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>

export default {
  data() {
    return {
      year: new Date().getFullYear(),
      menuItems: [
        {
            text: 'יצירת קשר',
            url: '/about'
        },
        {
            text: 'תקנון',
            url: '/terms_of_use'
        },
        {
            text: 'חיפוש טיולים',
            url: '/search_ride'
        },
        {
            text: 'אודות',
            url: '/about'
        },
        {
            text: 'עמוד הבית',
            url: '/'
        }
      ],
      mobileMenuItems: [
        {
            text: 'יצירת קשר',
            url: '/about'
        },
        {
            text: 'תקנון',
            url: '/terms_of_use'
        },
        {
            text: 'חיפוש טיולים',
            url: '/search_ride'
        },
        {
            text: 'אודות',
            url: '/about'
        },
        {
            text: 'עמוד הבית',
            url: '/'
        }

      ],
      socialIcons: [
        {
          width: "64px",
          height: "64px",
          icon: "facebook.svg",
          url: "https://www.facebook.com/tripplanner.co.il",
        },
        // {
        //   icon: "instagram.svg",
        //   url: "",
        // },
      ],
    };
  },
};
</script>
<style>
.bg {
  background-color: #061c18;
}
.caretup {
  font-size: 64px;
  font-weight: 100;
  line-height: 54px;
  height: 24px;
  width: 24px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #2a2a2a;
}
.left {
  position: absolute;
  left: 1em;
}
</style>
