<template>
  <div
    class="text-right"
    style="font-size: 14pxfont-size: 14px;
    letter-spacing: 0.045em;
    line-height: 18px;"
  >
    <span v-if="visible === false">
      <div  dir="rtl" >
      <span style="white-space: pre-wrap;">{{ text.slice(0, 200) }}</span>
      <span v-if="text > text.slice(0, 200)" 
            style="white-space: pre-wrap;">...</span>
      
      <span v-if="text > text.slice(0, 200)">
        <br />
        <b-link
          class="text-underline set_to_the_right_color"
          href
          @click.prevent="visible = !visible"
          :class="visible ? null : 'collapsed'"
          :aria-expanded="visible ? 'true' : 'false'"
          :aria-controls="'collapse-' + uniqueId"
          >הצג עוד</b-link
        >
      </span>
      </div>
    </span>
    <div>
      <!-- Element to collapse -->
      <b-collapse :id="'collapse-' + uniqueId" v-model="visible">
        <div  dir="rtl" >
        <span style="white-space: pre-wrap;">{{ text }}</span> <br />
        <b-link 
          href @click.prevent="visible = !visible"
          class="set_to_the_right_color"
          >הצג פחות</b-link>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    uniqueId: {
      type: [String, Number],
      required: false,
      default: null
    },
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style scoped>

.set_to_the_right_color{
  color: #007bff!important;
}
</style>