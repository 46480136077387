export function dmsToDecimal(dms, direction) {
  let decimalDegrees = 0;
  if (dms) {
    const [degrees, minutes, seconds] = dms;
    decimalDegrees = degrees + (minutes / 60) + (seconds / 3600);

    // Convert to negative if direction is South or West
    if (['S', 'W'].includes(direction)) {
      decimalDegrees = -decimalDegrees;
    }
  } else {
    console.log("Failed to parse geolocation", dms);
  }
  return decimalDegrees;
}
