<template>
  <main>
    <section v-if='rideHeader.ride' >     
      <div class="centering_with_gap flex rem-padding-right">
        <div 
          v-if="internallShouldPresentTheDates"
          class="centering_with_gap flex left_border_blue">
          <div class="field">
            <img 
             :src="require('@/assets/images/calendar_icon.png')"
             width="21px"
             height="24px"
             alt=""
            >
          </div>            
          <div class="field">
            <i class="calendar icon"></i>
            <span class="main_ride_dates">{{rideHeader.ride.end_date}}</span>
          </div>
          <img
            :src="require('@/assets/images/arrow-right-solid.png')"
            alt=""
            width="18px"
            height="20px"
          >
          <div class="field">
            <i class="calendar icon"></i>
            <span class="main_ride_dates">{{rideHeader.ride.start_date}}</span>
          </div>
        </div>
        <div class="centering_with_gap flex">
          <img 
            :src="require('@/assets/images/users_icon.png')"
            alt=""
            width="30px"
            height="24px"
            >
          <span class="main_ride_dates">{{numberOfParticipentText()}}</span>
        </div>
      </div>
      <PresentUserInfo/>
      <div class="trip-date-section">
        <div class="container">
          <div class="trip-textarea">
            <p
              class="ride-detailes"
              v-html="rideHeader.ride.text">
            </p>
            <p 
              v-if="doWeHaveUserPage(rideHeader.ride.authorId)" 
              @click="goToUserPage( rideHeader.ride.authorId )"
              class="user-more-rides text-center">
              לטיולים נוספים של <br>
              <span class="user-more-rides-btm btn btn-primary rounded-pill primary border-0">{{rideHeader.ride.authorName}}</span>
            </p>
            <p 
              v-else-if="rideHeader.ride.authorName" 
              @click="goToUserPage( rideHeader.ride.authorId )"
              class="user-more-rides text-center">
              מסלול הטיול תוכנן ע"י:  {{rideHeader.ride.authorName}}
            </p>
					</div>
					<div 
            v-if="allRide && allRide.highLevelRideLocations && allRide.highLevelRideLocations.length"
            class="trip-timeline-section"
          >
            <h3 class="text-center main-ride-titles">{{ selectedHeading }}</h3>
            <div class="row">
              <div class="hotels-sumary centering_line_with_gap flex padding-right-1_5rem">
                <div class="centering_with_gap padding-right-1_5rem" >
                  <div class="d-flex flex-wrap align-items-center gap-2 mt-3 padding-bottom-2"
                       style="justify-content: flex-end !important;">
                      <div
                        @click="setHeading('פעילויות')"
                        :class="['clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6 smaler-for-mobile',
                                  selectedHeading === 'פעילויות' ? 'activities-default': 'activities-activ'
                        ]"
                      >
                        פעילויות
                      </div>
                      <div 
                        @click="setHeading('מקומות לינה')"
                        :class="['clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6 smaler-for-mobile',
                                  selectedHeading === 'מקומות לינה' ? 'activities-default': 'activities-activ'
                        ]"
                        >
                          מקומות לינה
                      </div>
                  </div>

                  <div
                    v-if="selectedHeading === 'פעילויות'"
                  >
                    <div
                      v-for="(dailyActivites, index) in allRide.attractionsByDay"
                      :key="index"
                      @click="scrollMeTo('daily_attraction_day_' + index)"
                      class="day-at-trip clickable-item"
                    >
                      <div class="row mb-3">
                        <div class="d-flex">
                          <div class="ride-day-date">
                            <p class="day-number mb-0 fw-bold fs-4 text-end">
                              יום {{ index + 1 }}
                            </p>

                            <div 
                              v-for="(attraction, idx) in dailyActivites.daily_attractions"
                              :key="idx"
                            >
                              <p class="activity-name_at_sumary fs-4 text-end mb-0">
                                <span class="custom-bullet"></span>
                                {{attraction.title}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div 
                    v-if="selectedHeading === 'מקומות לינה'"
                  >
                    <div
                      v-for="(star, index) in allRide.highLevelRideLocations" 
                      @click="scrollMeTo(star.id + '_acommodation_ref_id')"
                      :key="star.id"
                      :class="[(index % 2 === 0) ? 'd-flex timeline-odd-location' : 'd-flex timeline-even-location',
                                { 'timeline-last-item': index === allRide.highLevelRideLocations.length - 1 }
                                ]"
                      >
                      <div
                        :class="[(index % 2 === 0) ? 'position-absolute timeline-odd-text' : 'position-absolute timeline-even-text']"
                        >
                        <p class="timeline-location-name">{{star.locationName}}</p>
                        <p 
                          v-if="star.numberOfDays > 1"
                          class="timeline-location-duration">
                          {{star.numberOfDays}} לילות
                        </p>
                        <p 
                          v-else
                          class="timeline-location-duration">
                          לילה אחד
                        </p>
                      </div>
                      <img 
                        alt="accommodationNumber"
                        class=""
                        width="211"
                        height="113"
                        :src="getDayImageUrl(index)"
                        >
                    </div>
                  </div>
                </div>
							</div>
              <div class="hotels-sumary padding-left-1_5rem">
								<div class="trip-map-one">
                  <GlobalMap 
                    passed_class="ride_samery_map">
                  </GlobalMap>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>



<script>
import GlobalMap from './GlobalMap';

import { mapActions, mapGetters } from "vuex";
import config from '@/common/config';
import PresentUserInfo from "@/components/PresentUserInfo";

export default {
    name: 'SingleRideSumary',
    components: {
      GlobalMap,
      PresentUserInfo
    },
    data() {
      return {
        selectedHeading: 'מקומות לינה', // Keeps track of the current heading
        internallShouldPresentTheDates: true,
      };
    },
    computed: mapGetters(['allRide',
                          'rideHeader',
                          ]),
    methods: {
      ...mapActions([ "shouldPresentTheDates",
                    ]),
      async internalDataShouldPresentTheDates(){
         this.internallShouldPresentTheDates = await this.shouldPresentTheDates()
      },
      scrollMeTo(refId){
        const element = document.getElementById(refId);
        if ( element ){
          const offset = 140; // Adjust this value to determine how much higher to scroll
          const topPosition = element.offsetTop - offset;
          window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
      },
      setHeading(heading) {
        this.selectedHeading = heading;
      },
      doWeHaveUserPage( authorId){
        return config.config.USER_PAGE_CONVERTER[ authorId ]
      },
      goToUserPage( authorId){
        window.location.href = config.config.USER_PAGE_CONVERTER[ authorId ].link

      },
      getDayImageUrl( i ){
        if( i < config.config.BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE.length )
          return config.config.BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE[i].IMAGE_URL
        else
          return config.config.BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE[ config.config.BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE.length -1 ].IMAGE_URL
      },
      numberOfParticipent(){
        if ( ! this.rideHeader )
          return 0;
        const globalRide  = this.rideHeader.ride;
        let numberOfPerticipent = 0 ;
        if( globalRide.number_of_child )
          numberOfPerticipent += globalRide.number_of_child;
        if( globalRide.number_of_teen )
          numberOfPerticipent += globalRide.number_of_teen;
        if( globalRide.number_of_adult )
          numberOfPerticipent += globalRide.number_of_adult;
        return numberOfPerticipent;
      },
      numberOfParticipentText(){
      if( this.numberOfParticipent() > 1 )
        return this.numberOfParticipent() + " מטיילים "
      else
        return "מטייל אחד" 
      },

    },
    watch: {
      allRide() {
        this.internalDataShouldPresentTheDates()
      }
    },
}


</script>

<style scoped>

.activities-default { 
  opacity: 0.5;
}
.activities-activ {
  opacity: 1;
}

@media (max-width: 550px) {
  .smaler-for-mobile{
    font-size: 1.1rem!important;
    padding: 3px 7px!important;
  }
}
/* General styles for buttons */
.clikable {
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 20px;
  text-align: center;
}

/* Faded effect for inactive buttons */
.primary {
  filter: brightness(0.8); /* Slightly darker */
  background-color: #e6e6e6; /* Neutral light gray background */
  color: #555; /* Neutral gray text */
  border: 2px solid #ccc; /* Light gray border */
}


/* Hover effects for all buttons */
.clikable:hover {
  transform: scale(1.02); /* Slight zoom for interactivity */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow */
}
.clickable-item {
  cursor: pointer;
}

clickable-item {
  cursor: pointer; /* Indicates it's clickable */
  transition: background-color 0.3s, transform 0.2s; /* Adds a smooth hover effect */
}
.clickable-item:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Light background on hover */
  transform: scale(1.02); /* Slight zoom effect */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow */
}


.custom-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #007bff; /* Adjust color */
  border-radius: 50%; /* Make it a circle */
  margin-right: 5px; /* Add spacing */
  vertical-align: middle; /* Align with text */
}
</style>
